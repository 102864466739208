import React, { useState, useEffect, useRef, Fragment } from 'react';
import HeaderView from '../../components/HeaderView';
import { Grid, Button, Container, TextField, Typography,
  FormControl, InputLabel, Select, Checkbox, Box } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import {
  useFirestoreConnect, isLoaded
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import Loader from './../../components/Loader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';
import Alert from './../../components/Alert';
import { CSVLink } from "react-csv";
import GetAppIcon from '@material-ui/icons/GetApp';

const ReportsView = () => {
  const csvInstance = useRef();
  const [collectionSelected, setCollectionSelected] = useState('Usuarios');
  useFirestoreConnect([
    {collection:'users', orderBy: ['name']},
    {collection:'groups', orderBy: ['name']},
    {collection:'events', orderBy: ['name']}
  ]);
  const users = useSelector(state => state.firestore.ordered.users);
  const groups = useSelector(state => state.firestore.ordered.groups);
  const events = useSelector(state => state.firestore.ordered.events);
  const [autocompleteValue, setAutocompleteValue] = useState();
  const [dateInitial, setDataInitial] = useState(moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'));
  const [dateFinal, setDataFinal] = useState(moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'));
  const [eventsInfo, setEventInfo] = useState({
    finished: '',
    pending: '',
    actives: '', 
    inactives: '',
    users: '',
    percentage: ''
  });
  const [pieData, setPieData] = useState(null);
  const [error, setError] = useState(null);
  const [eventsFilteredByGroup, setEventsFilteredByGroup] = useState([]);
  const [csvData, setCsvData] = useState();
  const [checkBox, setCheckBox] = useState(false);
  
  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [ csvData ]);

  if (!isLoaded(users) || !isLoaded(groups) || !isLoaded(events)) {
    return <Loader />;
  }

  const handleChangeCollectionSelect = event => {
    setCollectionSelected(event.target.value);
    setAutocompleteValue(null);
    setEventsFilteredByGroup([]);
    setPieData(null);
    setEventInfo({
      finished: '',
      pending: '',
      actives: '', 
      inactives: '',
      users: '',
      percentage: ''
    });
  }

  const onChangeDateInitial = event => {
    setDataInitial(event.target.value);
  }

  const onChangeDateFinal = event => {
    setDataFinal(event.target.value);
  }

  const onClickSearch = () => {
    setError(null);
    if(!autocompleteValue) {
      setError('Seleccione un usuario o grupo.');
      return;
    }

    const eventsCopy = [...events];
    const evenExampleIndex = eventsCopy.map(event => event.id).indexOf('I9A5wFOTSoQgWzFeLP0i'); 
    eventsCopy.splice(evenExampleIndex, 1);

    const evenExampleTwoIndex = eventsCopy.map(event => event.id).indexOf('QcbR8k4e9s74BSUiLqkw');
    eventsCopy.splice(evenExampleTwoIndex, 1);

    const eventsFilteredByDate = !checkBox 
      ? [...eventsCopy.filter(event => event.dateInitial >= dateInitial && event.dateFinal <= dateFinal)] : [...eventsCopy];
    
    let eventsFiltered = [];

    if(collectionSelected == 'Usuarios') {
      eventsFiltered = getEventsByUser(eventsFilteredByDate);
      getDataReportUser(eventsFiltered);
    }
    else {
      eventsFiltered = getEventsByGroup(eventsFilteredByDate);
      getDataReportGroup(eventsFiltered);
    }
  }

  const getEventsByUser = eventsFilteredByDate => {
    return eventsFilteredByDate.filter(event => 
      event.users.includes(autocompleteValue.id));
  }

  const getEventsByGroup = eventsFilteredByDate => {
    return eventsFilteredByDate.filter(event => event.groups && 
      event.groups.includes(autocompleteValue.id));
  }

  const getEventsUserFinished = eventsFiltered => {
    return eventsFiltered.filter(event => event.usersWhitEventComplete &&
      event.usersWhitEventComplete.includes(autocompleteValue.id)).length;
  }

  const getEventsPendingCount = eventsFiltered => {
    return eventsFiltered.filter(event => 
      (!event.usersWhitEventComplete || (event.usersWhitEventComplete && !event.usersWhitEventComplete.includes(autocompleteValue.id)))
      && (event.dateFinal == "" || (event.dateFinal > moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'))) ).length;
  }

  const getEventsIconmplete = eventsFiltered => {
    return eventsFiltered.filter(event => (event.users && event.users.includes(autocompleteValue.id)) && (event.dateFinal && event.dateFinal < moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) 
      && (!event.usersWhitEventComplete || event.usersWhitEventComplete && !event.usersWhitEventComplete.includes(autocompleteValue.id)) ).length;
  }

  const getEventActives = eventsFiltered => {
    return eventsFiltered.filter(event => 
      event.dateFinal == '' || (event.dateFinal && event.dateFinal > moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) ).length;
  }

  const getEventInactives = eventsFiltered => {
    return eventsFiltered.filter(event => 
      event.dateFinal != '' && event.dateFinal < moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')).length;
  }

  const getDataReportUser = eventsFiltered => {
    if(eventsFiltered.length > 0) {
      const eventsFinishedCount = getEventsUserFinished(eventsFiltered);
      const eventsPendingCount = getEventsPendingCount(eventsFiltered);
      const eventsIconmplete = getEventsIconmplete(eventsFiltered);
      let percentage = percentage = ((eventsFinishedCount / (eventsFinishedCount + eventsPendingCount + eventsIconmplete)) * 100).toFixed(2);
      
      if(isNaN(percentage)) {
        percentage = (0.00).toFixed(2);
      }

      setEventInfo({
        finished: eventsFinishedCount,
        pending: eventsPendingCount,
        actives: '', 
        inactives: '',
        incomplete: eventsIconmplete,
        percentage: percentage,
      });

      setPieData({
        labels: ['Eventos cumplidos','Eventos pendientes'],
          datasets: [
            {
              label: '',
              data: [
                  percentage,
                  100 - percentage
              ],
              backgroundColor: ['#87AA3B','#f44336']
            },
          ],
      });
    }
    else {
      setError('No se encontraron eventos.');
      setPieData();
      setEventInfo({
        finished: '',
        pending: '',
        actives: '', 
        inactives: '',
        users: '',
        percentage: ''
      });
      setEventsFilteredByGroup([]);
    }
  }

  const getPercentage = eventsFilteredByGroupAndDate => {
    let percentage = 0;
    const usersCount = autocompleteValue.users.length || 0;
    
    for (let i = 0; i < usersCount; i++) {
      const eventsCompleteCount = eventsFilteredByGroupAndDate.filter(event => event.usersWhitEventComplete && event.usersWhitEventComplete.includes(autocompleteValue.users[i])).length
      const eventsCount = eventsFilteredByGroupAndDate.filter(event => event.users && event.users.includes(autocompleteValue.users[i])).length
      let userPercentaje = ((eventsCompleteCount / eventsCount) * 100).toFixed(2);
      
      if(isNaN(userPercentaje)) { userPercentaje = 0 }
      
      percentage = percentage + Number(userPercentaje);
    }
    
    return (percentage / usersCount).toFixed(2);
  }

  const getDataReportGroup = eventsFiltered => {
    if(eventsFiltered.length > 0) {
      setEventsFilteredByGroup(eventsFiltered);
      const eventsActivesCount = getEventActives(eventsFiltered); 
      const eventsInactivesCount = getEventInactives(eventsFiltered);
      const percentage = getPercentage(eventsFiltered);

      setEventInfo({
        finished: '',
        pending: '',
        actives: eventsActivesCount,
        inactives: eventsInactivesCount,
        percentage: percentage,
        users: autocompleteValue.users ? autocompleteValue.users.length : 0
      });

      setPieData({
        labels: ['Usuarios que cumplieron','Usuarios pendientes'],
          datasets: [
            {
              label: '',
              data: [
                  percentage,
                  100 - percentage
              ],
              backgroundColor: ['#87AA3B','#f44336']
            },
          ],
      });
    }
    else {
      setError('No se encontraron eventos.');
      setPieData(null);
      setEventInfo({
        finished: '',
        pending: '',
        actives: '', 
        inactives: '',
        users: '',
        percentage: ''
      });
      setEventsFilteredByGroup([]);
    }
  }

  const getGroupUsers = userIds => {
    let usersGroup = [];

    if(userIds.length) {
      users.forEach(user => {
        if(userIds.includes(user.id)) {
          usersGroup.push({... user});
        }
      })
    }

    return usersGroup;
  }

  const getGroupUsersWhitPercentage = (groupUsers, eventsFilteredByGroupAndDate) => {
    for (let i = 0; i < groupUsers.length; i++) {
      const eventsCompleteCount = eventsFilteredByGroupAndDate.filter(event => event.usersWhitEventComplete && event.usersWhitEventComplete.includes(groupUsers[i].id)).length
      const eventsCount = eventsFilteredByGroupAndDate.filter(event => event.users && event.users.includes(groupUsers[i].id)).length
      
      groupUsers[i].percentage = ((eventsCompleteCount / eventsCount) * 100).toFixed(2);
      groupUsers[i].eventsCount = eventsCount;
      groupUsers[i].eventsCompleteCount = eventsCompleteCount;
    }

    return groupUsers;
  }

  const createReport = usersGroup => {
    let newCsvData = [];
    const usersGroupOrdered = usersGroup.sort((a, b) => (a.name && a.name > b.name) ? 1 : -1);

    newCsvData.push(['Yager']);
    newCsvData.push(['Grupo: ', autocompleteValue.name]);
    if(!checkBox){newCsvData.push(['Fecha de inicio: ', dateInitial]);}
    newCsvData.push([]);
    newCsvData.push(['Nombre', 'Email', 'Eventos', 'Cumplidos', 'Avance']);

    for (let i = 0; i < usersGroupOrdered.length; i++) {
      newCsvData.push([
        usersGroupOrdered[i].name ? usersGroupOrdered[i].name : 'Sin nombre', 
        usersGroupOrdered[i].email ? usersGroupOrdered[i].email : 'Sin email',
        usersGroupOrdered[i].eventsCount ? usersGroupOrdered[i].eventsCount : '0',
        usersGroupOrdered[i].eventsCompleteCount ? usersGroupOrdered[i].eventsCompleteCount : '0',
        usersGroupOrdered[i].percentage ? usersGroupOrdered[i].percentage + '%' : '0%'
      ])
    }

    return newCsvData;
  }

  const onClickDownload = () => {
    if(!eventsFilteredByGroup.length) {
      setError('Favor de realizar una busqueda por Grupos para realizar la descarga.');
      return;
    }
    if(!autocompleteValue.users.length) {
      setError('Este grupo no tiene usuarios');
      return;
    }

    const groupUsers = getGroupUsers(autocompleteValue.users);
    const usersGroupWhitPercentage = getGroupUsersWhitPercentage(groupUsers, eventsFilteredByGroup);
    return createReport(usersGroupWhitPercentage);
  }

  const handleChangeCheck = () => {
    setCheckBox(!checkBox);
  }

  return (
    <>
      <HeaderView
        Text='Reportes'
      />
      <Container>
        <div style={{marginTop: 20}} >
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Autocomplete
                onChange={(event, newValue) => {
                  setAutocompleteValue(newValue);
                }}
                options={collectionSelected == 'Usuarios' ? users : groups}
                getOptionLabel={(option) => option.name ? option.name : option.email ? option.email : 'Sin especificar'}
                renderInput={(params) => <TextField {...params} label={collectionSelected == 'Usuarios' ? 'Usuarios' : 'Grupos'} 
                variant="filled" 
                fullWidth />}
              />
            </Grid>
            <Grid item xs={6} md={2} >
              <FormControl variant="filled" fullWidth>
                <InputLabel>Buscar en</InputLabel>
                <Select
                  native
                  value={collectionSelected}
                  onChange={handleChangeCollectionSelect}
                >
                  <option value={'Usuarios'}>Usuarios</option>
                  <option value={'Grupos'}>Grupos</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField label="Fecha de inicio"
                variant="filled"  fullWidth type="datetime-local" 
                InputLabelProps={{ 
                    shrink: true
                }}
                onChange={onChangeDateInitial} value={dateInitial}  
                disabled={checkBox}
                inputProps={{ max: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm') }}
              />
            </Grid>
            <Grid item xs={6} md={1}>
              <Button variant='contained' fullWidth onClick={onClickSearch} style={{backgroundColor: '#ffc107', color: 'white'}}>
                Buscar
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              <Fragment>
                <div
                  onClick={async () => {
                    const newCsvData = await onClickDownload();
                    setCsvData(newCsvData);
                  }}
                >
                  <Button variant='contained' fullWidth startIcon={<GetAppIcon />}  
                    onClick={onClickDownload} style={{backgroundColor: '#ffc107', color: 'white'}}
                  >
                    Descargar XLS
                  </Button>
                </div>
                {csvData ?
                  <CSVLink
                    filename={"ReporteGrupo.csv"}
                    data={csvData}
                    ref={csvInstance}
                  />
                : undefined }
              </Fragment>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography align='center'>Sin rango de fechas</Typography>
              <Box display="flex" justifyContent="center">
                  <Checkbox value={checkBox} onChange={handleChangeCheck} ></Checkbox>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{marginTop: 20}}>
            <Grid item md={4}>
              <Grid container spacing={2} >
                <Grid item md={12}>
                  <Typography>
                  {collectionSelected == 'Usuarios' ? 'Usuario selecionado: ' : 'Grupo seleccionado: '}
                  {
                    autocompleteValue && autocompleteValue.name
                      ? autocompleteValue.name
                      : ''
                  }
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginTop: 25}}>
                <Grid item xs={12} md={12}><Typography>Eventos</Typography></Grid>
                {
                  collectionSelected == 'Usuarios'
                  ?
                    <>  
                      <Grid item xs={4} md={4}>
                        <Typography>Atendidos</Typography>
                        <Typography>{eventsInfo.finished}</Typography>
                      </Grid>
                      <Grid item xs={4}  md={4}>
                        <Typography>Pendientes</Typography>
                        <Typography>{eventsInfo.pending}</Typography>
                      </Grid>
                      <Grid item xs={4}  md={4}>
                        <Typography>Incompletos</Typography>
                        <Typography>{eventsInfo.incomplete}</Typography>
                      </Grid>
                    </>
                  :
                    <>
                      <Grid item  xs={4} md={4}>
                        <Typography>Activos</Typography>
                        <Typography>{eventsInfo.actives}</Typography> 
                      </Grid>
                      <Grid item  xs={4} md={4}>
                        <Typography>Inactivos</Typography>
                        <Typography>{eventsInfo.inactives}</Typography> 
                      </Grid>
                      <Grid item  xs={4} md={4}>
                        <Typography>Usuarios</Typography>
                        <Typography>{eventsInfo.users}</Typography> 
                      </Grid>
                    </>
              }
              </Grid>
              <Grid item xs={4} md={4} style={{marginTop: 30}}>
                  <Typography>Porcentage</Typography>
                  <Typography>{eventsInfo.percentage} %</Typography>
                </Grid>
            </Grid>
            <Grid item md={3}>
              <TextField label="Fecha de finalización"
                variant="filled"  fullWidth type="datetime-local" 
                InputLabelProps={{ 
                  shrink: true
                }}
                onChange={onChangeDateFinal} value={dateFinal}  
                disabled={checkBox}
              />
            </Grid>
            {
              pieData 
              ?
                <Grid item xs={12} md={5}>
                  <Typography align='center'>
                  {
                    autocompleteValue && autocompleteValue.name
                      ? autocompleteValue.name
                      : ''
                  }
                  </Typography>
                  <Pie  data={pieData} options={{maintainAspectRatio: true}} />
                </Grid>
              :
                null
            }
              
          </Grid>
        </div>
      </Container>
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity="error" />
    </>
  );
}

export default ReportsView;