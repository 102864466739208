import React from "react";
import './styles.css';
import background from './bg-interior.png';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import theme from './components/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import RouteAuth from './components/RouteAuth';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';

import {
  HomeView,
  LogInView,
  LogOutView,
  UsersView,
  ProfileView,
  GroupsView,
  GroupCreateView,
  GroupEditView,
  EventsView,
  FormEventsView,
  ReportsView, 
  LinksEventsView,
  LinkEventView,
  UserEditView,
  UserCreateView, 
  NoticePrivacy,
  WebView,
  EventsEvidenceView,
  EventsProgressView
} from './views';

const containerStyle = {
  backgroundColor: '#fdfdfd',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  minHeight: '100vh'
};

const bodyStyles = {
  margin: 0,
  padding: 0
};

export default () => {
  const auth = useSelector(state => state.firebase.auth);
  let user = null, loading = true;
  if (isLoaded(auth)){
    user = isEmpty(auth)? null : auth;
    loading = false;
  }

  return (
    <div style={ containerStyle }>
      <ThemeProvider theme={theme}>
        <Router>
          { user && !loading ? <Header hideHeaderLinks /> : null }
          <div style={user && !loading ? bodyStyles : null }>
            <Switch>
              <RouteAuth exact path="/login" component={LogInView} />
              <RouteAuth exact path="/" component={HomeView} requireAuth />
              <RouteAuth exact path="/logout" component={LogOutView} requireAuth />
              <RouteAuth exact path="/users/list/:page" component={UsersView} requireAuth />
              <RouteAuth exact path="/users/new" component={UserCreateView} /> 
              <RouteAuth exact path="/users/edit/:id/:page" component={UserEditView} /> 
              <RouteAuth exact path="/profile" component={ProfileView} requireAuth />
              <RouteAuth exact path="/groups/list/:page" component={GroupsView} requireAuth />
              <RouteAuth exact path="/groups/create/:page" component={GroupCreateView} requireAuth />
              <RouteAuth exact path="/groups/edit/:id/:page" component={GroupEditView} requireAuth />
              <RouteAuth exact path="/events/list/:page" component={EventsView} requireAuth />
              <RouteAuth exact path="/events/formEvents/:id/:page" component={FormEventsView} requireAuth />
              <RouteAuth exact path="/events/evidence/:id/:page" component={EventsEvidenceView} requireAuth />
              <RouteAuth exact path="/events/progress/:id/:page" component={EventsProgressView} requireAuth />
              <RouteAuth exact path="/reports" component={ReportsView} requireAuth />
              <RouteAuth exact path="/links" component={LinksEventsView} />
              <RouteAuth exact path="/link/:idEvent/:idUser" component={LinkEventView} /> 
              <RouteAuth exact path="/noticePrivacy" component={NoticePrivacy} /> 
              <RouteAuth exact path="/web" component={WebView} />
            </Switch>
          </div>
          { user && !loading? <Footer/> : null }
        </Router>
      </ThemeProvider>
    </div>
  )
}
