import React from 'react';
import { 
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, TextField
} from '@material-ui/core';

const NotificationDialogView = ({open, onCancel, onAccept, message}) => {
  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>Enviar notificación</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          required
          label="Mensaje de notificacion"
          variant="outlined"
          onChange={(event) =>  message(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}  color="secondary">
          Cancelar
        </Button>
        <Button onClick={onAccept}  color="secondary" autoFocus>
          Aceptar
        </Button>
      </DialogActions>
  </Dialog>
  );
};

export default NotificationDialogView;