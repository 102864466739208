import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { Container, Grid, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        height : '100%',
        fontFamily: 'roboto',
    },
    title: {
        fontSize: 35
    },
    subTitle: {
        fontSize: 15,
    },
    marginDiv: {
        marginTop: 10
    }
}));

const NoticePrivacyView = () => {
    const classes = useStyles();
    
    return (
        <div>
            <Container style={{marginBottom: 50}}>
                <Box display="flex" justifyContent="flex-start">
                    <Box xs={12} item md={6} style={{marginTop: 20}}>
                        <p className={classes.title}> AVISO DE PRIVACIDAD </p>
                    </Box>
                </Box>
                <Grid container>
                    <div className={classes.root}>
                        <Box display="flex" justifyContent="center">
                            <Box xs={12} item md={6} style={{marginTop: 10}}>
                                <Typography className={classes.subTitle} >
                                    <strong>PROYECTO YAGER GLOBAL, S.A. DE C.V</strong>
                                </Typography>
                                <Typography className={classes.subTitle} >
                                    <strong>AVISO DE PRIVACIDAD ELECTRÓNICO</strong>
                                </Typography>
                            </Box>
                        </Box>
                        <br />
                        <div>
                            <Typography><strong>1. Identidad del responsable.</strong></Typography>
                            <Typography>
                                PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., es una empresa comprometida en proteger la privacidad de las personas con las que trata, razón por la cual emite el presente Aviso de Privacidad electrónico.
                                El Abogado General de PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., es la persona responsable de la protección de la información como lo son los Datos Personales, de las personas con las que la empresa trata.
                            </Typography>
                        </div>
                        <div className={classes.marginDiv}>
                            <Typography><strong>2. Datos Personales.</strong></Typography>
                            <Typography>
                                Para dar cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de Particulares, PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., emite el presente Aviso de Privacidad Electrónico, para que sus clientes, proveedores y personas interesadas, conozcan el mismo, y sepan que su información como lo son sus Datos Personales, serán debidamente manejados y protegidos.
                            </Typography>
                        </div>
                        <div className={classes.marginDiv}>
                            <Typography><strong>3. Finalidad y tratamiento de los Datos Personales.</strong></Typography>
                            <Typography>
                                La información personal que proporciona será utilizada por PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., para: contactarle, solicitarle servicios, proporcionarle servicios, celebrar contratos y demás fines relacionados con el objeto social de la empresa.
                            </Typography>
                        </div>
                        <div className={classes.marginDiv}>
                            <Typography><strong>4. Transmisión de la Información.</strong></Typography>
                            <Typography>
                                PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., podrá compartir o transmitir su información con otras personas físicas o morales, oficiales y particulares.
                            </Typography>
                        </div>
                        <div className={classes.marginDiv}>
                            <Typography><strong>5. Ejercicio de los Derechos de Acceso, Rectificación, Cancelación y Oposición.</strong></Typography>
                            <Typography>
                                El Acceso, Rectificación, Cancelación u Oposición conocidos como “Derechos ARCO”, respecto a la información que proporcione a PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., lo podrá hacer mediante entrega de una solicitud que dirija al Departamento legal de la empresa.
                            </Typography>
                        </div>
                        <div className={classes.marginDiv}>
                            <Typography><strong>6. Control y Seguridad de la información. </strong></Typography>
                            <Typography>
                                PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., se compromete a tomar las medidas necesarias para proteger la información recopilada de las personas con las que trata.
                            </Typography>
                        </div>
                        <div className={classes.marginDiv}>
                            <Typography><strong>7. Cambios al Aviso de Privacidad. </strong></Typography>
                            <Typography>
                                PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., podrá en cualquier momento, actualizar su Aviso de Privacidad Electrónico.
                            </Typography>
                        </div>
                        <div className={classes.marginDiv}>
                            <Typography>
                                En el caso de que se produzcan cambios sustanciales al aviso lo haremos de su conocimiento.
                            </Typography>
                        </div>
                        <div className={classes.marginDiv}>
                            <Typography>
                                EL PRESENTE DOCUMENTO ES SOLO PARA FINES INFORMATIVOS. EL AVISO DE PRIVACIDAD DE PROYECTO PROYECTO YAGER GLOBAL, S.A. DE C.V., LO PODRA OBTENER EN NUESTRA OFICINA, EN EL DEPARTAMENTO LEGAL, EN DÍAS HÁBILES Y EN HORARIO DE OFICINA.
                            </Typography>
                        </div>
                    </div>
                    <div>
                        <p>Blvd. Morelos 307, planta baja</p>
                        <p>edificio H, Col. Militar XV.</p>
                        <p>C.P. 83145 Hermosillo, Sonora.</p>
                    </div>
                </Grid>
                <br />
            </Container>
        </div>
    );
}

export default NoticePrivacyView;