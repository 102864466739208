import React, { useState, useEffect} from 'react';
import Container from './../../../components/Container';
import Loader from './../../../components/Loader';
import Table from './../../../components/Table';
import ViewHeader from './../../../components/HeaderView';
import ActivityLevel from './../../events/EventsView/ActivityLevel';
import { Delete, Edit, Notifications } from '@material-ui/icons';
import TableIconButton from './../../../components/TableIconButton';
import { makeStyles } from '@material-ui/core/styles';
import DeleteDialog from './../../../components/DeleteDialog';
import { useSelector } from 'react-redux';
import {
  useFirestoreConnect, isLoaded, useFirestore
} from 'react-redux-firebase';
import moment from 'moment-timezone';
import axios from 'axios';
import NotificationDialog from './NotificationDialogView';
import Alert from './../../../components/Alert';

const useStyles = makeStyles(theme => ({
  actions: {
    '& > *': {
      margin: theme.spacing(0, 1)
    }
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

const header = [
  {
    prop: 'name',
    name: 'Nombre',
  },
  {
    prop: 'city',
    name: 'Ciudad'
  },
  {
    prop: 'registry',
    name: 'Registro'
  }
];


const UsersView = ({match, history }) => {
  const classes = useStyles();
  const firestore = useFirestore();
  useFirestoreConnect(['users','events']);
  const [ idUserToDelete, setIdUserToDelete ] = useState(null);
  const users = useSelector(state => state.firestore.ordered.users);
  const events = useSelector(state => state.firestore.ordered.events);
  const [ page, setPage ] = useState(parseInt(match.params.page) || 0);
  const [userId, setUserId] = useState();
  const [message, setMessage] = useState('');
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(users && events) {
      const usersCopy = [...users];
      const eventsCopy = [...events];

      const evenExampleIndex = eventsCopy.map(event => event.id).indexOf('I9A5wFOTSoQgWzFeLP0i'); 
      eventsCopy.splice(evenExampleIndex, 1);

      const evenExampleTwoIndex = eventsCopy.map(event => event.id).indexOf('QcbR8k4e9s74BSUiLqkw'); 
      eventsCopy.splice(evenExampleTwoIndex, 1);

      const userWhitPercentage = getUsersWhitPercentage(usersCopy, eventsCopy);

      setUsersFiltered(userWhitPercentage);
      setLoaded(true);
    }
  }, [users, events])

  const getUsersWhitPercentage = (users, events) => {
    let usersWhitPercentage = [];

    users.forEach(user => {
      const userEventsCompleteCount = events.filter(event => event.usersWhitEventComplete && event.usersWhitEventComplete.includes(user.id)).length;
      const userEventsPendingCount = events.filter(event => event.users && event.users.includes(user.id) 
          && (!event.usersWhitEventComplete || (event.usersWhitEventComplete && !event.usersWhitEventComplete.includes(user.id)) )
          && (event.dateFinal == "" || event.dateFinal > moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'))).length;
      const eventsIncompleteCount = events.filter(event => (event.users && event.users.includes(user.id)) && (event.dateFinal && event.dateFinal < moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) 
          && (!event.usersWhitEventComplete || event.usersWhitEventComplete && !event.usersWhitEventComplete.includes(user.id)) ).length;
      let userPercentage = ((userEventsCompleteCount 
        / (userEventsCompleteCount + userEventsPendingCount + eventsIncompleteCount)) * 100).toFixed(2);
      const userCopy = {...user};
      
      if(isNaN(userPercentage)) { userPercentage = (0).toFixed(2) }
      
      userCopy.percetage = userPercentage;

      usersWhitPercentage.push(userCopy);
    });

    return usersWhitPercentage;
  }

  if (!isLoaded(users) || !isLoaded(events) || !loaded) {
    return <Loader />;
  }

  const handleEdit = id => {
    history.push(`/users/edit/${ id }/${ page }`);
  }

  const handleDeleteSelect = id => {
    setIdUserToDelete(id);
  }

  const handleDelete = () => {
    firestore
      .collection('users')
      .doc(idUserToDelete)
      .delete()
      .then(() => {
        setIdUserToDelete(null);
      });
  }

  const sendNotification = () => {
    if(message) {
      const data = {
        userId: userId,
        message: message 
      };
      
      axios.post(`https://us-central1-yagermx.cloudfunctions.net/app/send-notification-user`, data)
        .then(res => {
          if(res) {
            setOpenDialogSuccess(true);
            setAlertMessage('Notificacion envida con exito!');
            setUserId(null);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  return (
    <>
      <ViewHeader
        Text="Usuarios"
        rowsCount={users.length}
      />
      <Container>
        <Table
          filter={['name', 'phone', 'email', 'facebook', 'twitter', 'instagram']}
          paginated
          header={header}
          data={usersFiltered}
          extraRows={[
            {
              prop: 'activity-level',
              name: 'Nivel de Actividad',
              cell: row => (
                <ActivityLevel level={row.percetage || 0}/>
              )
            },
            {
              prop: 'actions',
              name: 'Modificar',
              cell: row => (
                <span className={classes.actions}>
                  <TableIconButton
                    onClick={() => handleEdit(row.id)}
                    icon={Edit}/>
                  <TableIconButton
                    onClick={() => handleDeleteSelect(row.id)}
                    icon={Delete}/>
                </span>
              )
            },
            {
              prop: 'notification',
              name: 'Enviar notificación',
              cell: row => (
                <TableIconButton 
                  onClick={() => {setUserId(row.id);} }
                  icon={Notifications}
                />
              )
            },
          ]}
          _page={page}
          onChangePage={page => setPage(page)}
        />
      </Container>
      <DeleteDialog
        open={Boolean(idUserToDelete)}
        onClose={() => setIdUserToDelete(null)}
        onCancel={() => setIdUserToDelete(null)}
        onAccept={handleDelete}
        />
      <NotificationDialog 
        open={Boolean(userId)}
        onCancel={() => setUserId(null)}
        onAccept={() => sendNotification()}
        message={(_message) => setMessage(_message)}
      />
      <Alert
        open={openDialogSuccess}
        onClose={() => setOpenDialogSuccess(false)}
        message={alertMessage}
        severity="success" 
      />
    </>
  );
};

export default UsersView;