import React, { useState } from 'react';
import {
  Grid, TextField, Button,
  Select, MenuItem, FormControl,
  InputLabel
} from '@material-ui/core';

const isValidEmail = email => {
  const emailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegularExpression.test(email);
}

const UsersForm = ({ user, onChange, onSubmit, submitLabel, onBack}) => {

  const [ invalidFields, setInvalidFields ] = useState([]);

  const {
    name = '',
    sex = '',
    email = '',
    code = '',
    phone = '',
    facebook = '',
    instagram = '',
    twitter = '',
    role = '',
    city = '',
  } = user;

  const handleChange = event => {
    const { name, value } = event.target;
    const modifiedUser = { ...user, [name]: value };
    onChange(modifiedUser);
  }

  const handleInvalidInput = event => {
    setInvalidFields([ ...invalidFields, event.target.name ]);
  }

  const handleBeforeSubmit = () => {
    const invalidFields = [];
    if (!isValidEmail(user.email)) { // special validation for email
      invalidFields.push('email');
    }
    if (!user.sex) { // required prop doesn't work for select input in material-ui
      invalidFields.push('sex'); 
    }
    if (!user.role) { // required prop doesn't work for select input in material-ui
      invalidFields.push('role');
    }
    setInvalidFields(invalidFields);
  }

  const handleSubmit = event => {
    event.preventDefault();
    if (invalidFields.length === 0) {
      onSubmit && onSubmit(event);
    }
  }

  return (
    <Grid item xs={8}>
      <form
        onSubmit={handleSubmit}>
        <Grid
          justify="flex-end"
          container
          spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={name}
              required
              label="Nombre"
              name="name"
              onInvalid={handleInvalidInput}
              variant="filled"
              error={invalidFields.includes('name')}
              onChange={handleChange}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="filled"
              error={invalidFields.includes('sex')}
              fullWidth>
              <InputLabel>
                Género
              </InputLabel>
              <Select
                value={sex}
                name="sex"
                onChange={handleChange}
                required>
                <MenuItem value=""></MenuItem>
                <MenuItem value="h">Hombre</MenuItem>
                <MenuItem value="m">Mujer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              onInvalid={handleInvalidInput}
              fullWidth
              value={email}
              label="Correo"
              required
              name="email"
              type="email"
              variant="filled"
              error={invalidFields.includes('email')}
              onChange={handleChange}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              />
          </Grid>
          <Grid item xs={6}>
            <TextField
              onInvalid={handleInvalidInput}
              fullWidth
              name="code"
              value={code}
              label="Código"
              required
              error={invalidFields.includes('code')}
              onChange={handleChange}
              variant="filled"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Facebook"
              name="facebook"
              value={facebook}
              onChange={handleChange}
              variant="filled"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Twitter"
              name="twitter"
              value={twitter}
              onChange={handleChange}
              variant="filled"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Instagram"
              name="instagram"
              value={instagram}
              onChange={handleChange}
              variant="filled"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              onInvalid={handleInvalidInput}
              fullWidth
              label="Celular"
              name="phone"
              value={phone}
              required
              onChange={handleChange}
              error={invalidFields.includes('phone')}
              variant="filled"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="filled"
              error={invalidFields.includes('role')}
              fullWidth>
              <InputLabel>
                Rol
              </InputLabel>
              <Select
                value={role}
                name="role"
                onChange={handleChange}
                required>
                <MenuItem value=""></MenuItem>
                <MenuItem value="Cliente">Cliente</MenuItem>
                <MenuItem value="Administrador">Administrador</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              onInvalid={handleInvalidInput}
              fullWidth
              required
              label="Ciudad"
              error={invalidFields.includes('city')}
              variant="filled"
              name="city"
              value={city}
              onChange={handleChange}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              />
          </Grid>
          <Grid item>
            <Button 
              variant="contained" 
              color="secondary"
              type="button" 
              onClick={onBack} 
            >
              REGRESAR
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              onClick={handleBeforeSubmit}
              variant="contained"
              color="secondary">
              { submitLabel || 'Guardar' }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default UsersForm;