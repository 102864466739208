import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    color: '#a1a1a1'
  }
}));

const TableIconButton = ({ icon: Icon, ...rest }) => {
  const classes = useStyles();
  return (
    <Icon
      {...rest}
      className={classes.root} />
  );
};

export default TableIconButton;