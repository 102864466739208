import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '33px 15px 120px 15px',
    position: 'relative',
    maxWidth: '1100px',
    margin: '0 auto',
    '&.no-p-top': {
      paddingTop: '0'
    }
  }
}));

const Container = ({ children, withoutPaddingtop }) => {
  const classes = useStyles();

  let className = classes.root;
  if (withoutPaddingtop) {
    className += ' no-p-top';
  }

  return (
    <div className={className}>
      { children }
    </div>
  );
};

export default Container;