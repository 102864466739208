import React, { useState, useEffect } from 'react';
import HeaderView from '../../components/HeaderView';
import { Container, Typography, Box, Card, Divider, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFirestore } from 'react-redux-firebase';
import Loader from '../../components/Loader';
import CSVReader from 'react-csv-reader'
import Alert from './../../components/Alert';
import { CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon } from '@material-ui/icons/';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        paddingBottom: '100px'
    },
    exportLabel: {
        backgroundColor: '#4caf50',
        fontFamily: 'roboto',
        padding : 5,
        borderRadius: '5%',
        marginTop: '2%',
        color: 'white'
    },
    btn: {
        backgroundColor: '#ffc107', 
        color: 'white'
    },
}));

const EventsEvidenceView = ({match, history}) => {
    const classes = useStyles();
    const firestore = useFirestore();
    const [loaded, setLoaded] = useState(false);
    const [eventData, setEventData] = useState();
    const [error, setError] = useState(null);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [csvData, setCsvData] = useState([]);
    
    useEffect(() => {
        firestore.collection('events').doc(match.params.id).get().then((document) => {
            setEventData(document.data());
            setLoaded(true);
        });
    }, [])

    if (!loaded) {
        return <Loader />;
    }

    const csvImport = async csvData  =>  {
        setError(null);
        setCsvData([]);
        
        if(!csvData.length) {
            setError('Csv en blanco.');
            return;
        }
        if(!eventData.users.length) {
            setError('Este evento no tiene usuarios asignados.');
            return;
        }

        setLoadingCsv(true);
        
        let csvDataNames = [];
        let eventUsers = [];

        csvData.forEach(row => {
            row[1] = false;
            if(row[0]) { csvDataNames.push(row); }
        })
       
        for(let index = 0; index < eventData.users.length; index++) {
            const eventDocument = await firestore.collection('users').doc(eventData.users[index]).get();
            
            if(eventDocument.exists) {
                eventUsers.push({id : eventDocument.id, ...eventDocument.data()});
            }
        }
        
        const userDataFoundInCsv = getUserDataFoundInCsv(csvData, eventUsers, eventData.net);
        const _csvData = getCsvData(csvDataNames, userDataFoundInCsv.usersFoundInCsv);
        
        setCsvData(_csvData);

        if(userDataFoundInCsv.usersFoundInCsv.length) {
            const copyEventData = {...eventData};
            let usersWhitEventComplete = copyEventData.usersWhitEventComplete ? copyEventData.usersWhitEventComplete : [];

            if(usersWhitEventComplete.length) {
                for (let index = 0; index < userDataFoundInCsv.userIdsFoundInCsv.length; index++) {
                    if(!usersWhitEventComplete.includes(userDataFoundInCsv.userIdsFoundInCsv[index])) {
                        usersWhitEventComplete.push(userDataFoundInCsv.userIdsFoundInCsv[index]);
                        console.log(usersWhitEventComplete, userDataFoundInCsv.userIdsFoundInCsv[index])
                    }
                }
            }
            else {
                usersWhitEventComplete = userDataFoundInCsv.userIdsFoundInCsv;
            }

            firestore.collection('events').doc(match.params.id).update({
                usersWhitEventComplete: usersWhitEventComplete
            });
        }
      
        setLoadingCsv(false);
    }

    const getCsvData = (csvDataNames, usersFoundInCsv) => {
        csvDataNames.forEach(name => {
            usersFoundInCsv.forEach(userName => {
                if(name[0] == userName) {
                    name[1] = true;
                } 
            });
        })

        return csvDataNames;
    }

    const getUserDataFoundInCsv = (csvData, eventUsers, eventType) => {
        let userIdsFoundInCsv = [];
        let usersFoundInCsv = [];

        if(eventType == 'Facebook') {
            csvData.forEach(row => {
                if(row[0]) {
                    eventUsers.forEach(user => {
                        if ( (user.facebook && toWhitOutTilde(user.facebook) == toWhitOutTilde(row[0]))
                            || (user.name && toWhitOutTilde(user.name) == toWhitOutTilde(row[0]))
                            || (user.email && user.email == row[0]) 
                            || (user.phone && user.phone == row[0])
                        ){
                            if(!userIdsFoundInCsv.includes(user.id)) {
                                userIdsFoundInCsv.push(user.id);
                                usersFoundInCsv.push(row[0]);
                            }
                        }
                    })
                }
            });
        }
        else
        if(eventType == 'Instagram') {
            csvData.forEach(row => {
                if(row[0]) {
                    eventUsers.forEach(user => {
                        if ( (user.instagram && toWhitOutTilde(user.instagram) == toWhitOutTilde(row[0]))
                            || (user.name && toWhitOutTilde(user.name) == toWhitOutTilde(row[0]))
                            || (user.email && user.email == row[0]) 
                            || (user.phone && user.phone == row[0])
                        ){
                            if(!userIdsFoundInCsv.includes(user.id)) {
                                userIdsFoundInCsv.push(user.id);
                                usersFoundInCsv.push(row[0]);
                            }
                        }
                    })
                }
            });
        }
        else
        if(eventType == 'Twitter') {
            csvData.forEach(row => {
                if(row[0]) {
                    eventUsers.forEach(user => {
                        if ( (user.twitter && toWhitOutTilde(user.twitter) == toWhitOutTilde(row[0]))
                            || (user.name && toWhitOutTilde(user.name) == toWhitOutTilde(row[0]))
                            || (user.email && user.email == row[0]) 
                            || (user.phone && user.phone ==  row[0])
                        ){
                            if(!userIdsFoundInCsv.includes(user.id)) {
                                userIdsFoundInCsv.push(user.id);
                                usersFoundInCsv.push(row[0]);
                            }
                        }
                    })
                }
            });
        }
        else {
            csvData.forEach(row => {
                if(row[0]) {
                    eventUsers.forEach(user => {
                        if ( (user.facebook && toWhitOutTilde(user.facebook) == toWhitOutTilde(row[0]))
                            || (user.instagram && toWhitOutTilde(user.instagram) == toWhitOutTilde(row[0]))
                            || (user.twitter && toWhitOutTilde(user.twitter) == toWhitOutTilde(row[0]))
                            || (user.name && toWhitOutTilde(user.name) == toWhitOutTilde(row[0]))
                            || (user.email && user.email == row[0]) 
                            || (user.phone && user.phone == row[0])
                        ){
                            if(!userIdsFoundInCsv.includes(user.id)) {
                                userIdsFoundInCsv.push(user.id);
                                usersFoundInCsv.push(row[0]);
                            }
                        }
                    })
                }
            });
        }
        
        return {
            userIdsFoundInCsv: userIdsFoundInCsv,
            usersFoundInCsv: usersFoundInCsv
        }
    }

    const toWhitOutTilde = string => 
        string.toLowerCase().normalize('NFD')
           .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
           .normalize();
    
    return (
        <div className={classes.root}>
            <HeaderView Text={'Evidencia'} />
            <Container >
                <br />
                <Button variant="contained" className={classes.btn} 
                    onClick={() => history.push('/events/list/'+ match.params.page)}
                >
                    Regresar
                </Button>
                <Box display="flex" justifyContent="center">
                    <label className={classes.exportLabel}>
                        Importar excel
                        <CSVReader onFileLoaded={(data) => csvImport(data)} 
                            inputStyle={{display: 'none'}}
                            fileEncoding='UTF-8'
                        />
                    </label>
                </Box>
                <br />
                <Box display="flex" justifyContent="center">
                    { loadingCsv ? <CircularProgress /> : null }
                </Box>
                <br />
                {
                    csvData.length
                    ?  
                        <>
                        <Box display="flex" justifyContent="center">
                            <Typography className={classes.fontSubTitles2}>Usuarios</Typography>
                        </Box>
                        <br />
                        <Box display="flex" justifyContent="center">
                            <Card style={{marginTop:3}}>
                                <div style={{height: 300, width: 400, overflowY: 'auto', overflowX:'hidden'}}>
                                { csvData.map((name, index) => (
                                    <div  style={{margin:10}} key={index}>
                                           <div style={{ width: '100%' }}>
                                                <Box display="flex" p={1} bgcolor="background.paper">
                                                    <Box flexGrow={1} >
                                                        <Typography className={classes.fontTitles}>{name[0]}</Typography>
                                                    </Box>
                                                    <Box >
                                                    {
                                                        name[1]
                                                        ?
                                                            <CheckCircleIcon style={{color: '#87AA3B'}} />
                                                        :
                                                            <CancelIcon style={{color: '#f44336'}} />
                                                    }
                                                    </Box>
                                                </Box>
                                            </div>
                                        <Divider />
                                    </div>
                                ))}
                                </div>
                            </Card>
                        </Box>
                        </>
                    : 
                        null
                }
            </Container>
            <Alert
                open={Boolean(error)}
                onClose={() => setError(null)}
                message={error}
                severity="error" />
        </div>
    );
}

export default EventsEvidenceView;