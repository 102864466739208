import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Container from './../../../components/Container';
import Alert from './../../../components/Alert';
import UserForm from './../UserForm';
import UserDetails from './../UserDetails';
import ViewHeader from './../../../components/HeaderView';
import { useFirestore } from 'react-redux-firebase';
import moment from 'moment-timezone';

const UserCreateView = ({ history }) => {

  const firestore = useFirestore();
  const [ user, setUser ] = useState({});
  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false); 

  const handleChange = user => {
    setUser(user);
  }

  const handleSubmit = event => {
    event.preventDefault();
    firestore
      .collection('users')
      .doc()
      .set(getUserObject())
      .then(() => {
        history.push("/users")
      })
      .catch(() => setError(true));
  }

  const getUserObject = () => {
    const userProps = ['city', 'code', 'email', 'level',
      'name', 'phone', 'registry', 'role', 'sex'];
    const userObject = {};
    userProps.forEach(prop => {
      userObject[prop] = user[prop] || '';
    });
    userObject.registry = moment().tz('America/Hermosillo').format('DD/MM/YYYY');
    userObject.level = 0;
    userObject.facebook = '';
    userObject.twitter = '';
    userObject.instagram = '';
    userObject.groups = [];

    return userObject;
  }

  return (
    <>
      <ViewHeader 
        Text="Crear Nuevo Usuario"/>
      <Container press>
        <Grid container spacing={2}>
          <UserDetails />
          <UserForm
            user={user}
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitLabel="Agregar"
            />
        </Grid>
      </Container>
      <Alert
        open={error}
        onClose={() => setError(false)}
        message={'No se pudo crear el usuario.'} />
      <Alert
        open={success}
        onClose={() => setSuccess(false)}
        message={'El usuario se creó exitosamente.'} />  
    </>
  );
};

export default UserCreateView;