import React from 'react';
import { 
  Button, Card,
  Dialog, DialogActions,
  DialogContent, 
  DialogTitle, Divider
} from '@material-ui/core';

const EvidencesDialogView = ({ open, onAccept, evidences }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description">
      <DialogTitle id="delete-dialog-title">Evidencias</DialogTitle>
      <DialogContent>
      { 
        evidences.map((evidence) => (
          <div  style={{margin:10}} key={evidence}>
            <Card>
                <img src={evidence} height='300' width='250'/>
            </Card>
          </div>
        ))
      }
      </DialogContent>
      <DialogActions>
          <Button onClick={onAccept} color="primary" autoFocus>
          Aceptar
          </Button>
      </DialogActions>
  </Dialog>
  );
};

export default EvidencesDialogView;