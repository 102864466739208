import React  from 'react';
import { Grid, Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'roboto',
    },
    button: {
        backgroundColor: '#ffc107', color: 'white', 
    },
    div: {
        textAlign: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    iconFacebook: {
        color: '#4267B2',
        height: 80,
        width: 80,
        padding: 1
    },
    iconTwitter: {
        color: '#00acee',
        height: 80,
        width: 80,
        padding: 1
    },
    iconInstagram: {
        color: '#E1306C',
        height: 80,
        width: 80,
        padding: 1
    }
}));

const LinksEventsView = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                <div style={{ width: '100%' }}>
                    <Box display="flex" p={1} >
                        <Box p={1} flexGrow={1} >
                            <strong>Evento:</strong> mujeres exitosas
                        </Box>
                        <Box p={1} >
                        <strong>Fecha inicio:</strong> 13-03-2020
                        </Box>
                        <Box p={1}>
                        <strong>Fecha fin:</strong> 18-03-2020
                        </Box>
                    </Box>
                    <Box display="flex" p={1} style={{marginTop: '-30px'}} >
                        <Box p={1} flexGrow={1} >
                            <strong>Acción:</strong> Apoyar
                        </Box>
                       
                    </Box>
                </div>
            </Container>
            <div className={classes.div}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{paddingTop:'1%'}}>
                        <a href="https://www.facebook.com/153118154700641/posts/3123888140956946/">
                            <FacebookIcon className={classes.iconFacebook}>
                            </FacebookIcon>
                        </a>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <a href="https://www.instagram.com/tv/B9pO4elHycZ/?igshid=1sd3aqs25nyfc"> 
                            <InstagramIcon variant="contained" className={classes.iconInstagram}> 
                            </InstagramIcon> 
                        </a>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <a href="https://twitter.com/claudiapavlovic/status/1238184850227486720?s=21">  
                            <TwitterIcon className={classes.iconTwitter}>
                            </TwitterIcon>
                        </a>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <a href="https://www.facebook.com/153118154700641/posts/3122285154450578/">
                            <FacebookIcon className={classes.iconFacebook}>
                            </FacebookIcon>
                        </a>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <a href="https://www.facebook.com/ClaudiaPavlovich/videos/3396877743728376/">
                            <FacebookIcon className={classes.iconFacebook}> 
                            </FacebookIcon>
                        </a>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
export default LinksEventsView;