import React from 'react';
import { 
  Avatar, Button, Grid
} from '@material-ui/core';
import {
  Person as PersonIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  button: {
    backgroundColor: '#888888',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#797979'
    }
  },
  grid: {
    marginRight: theme.spacing(2)
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  }
}));

const getFirstLetter = (name, email) => {
  return (name || email)[0].toUpperCase();
}

const UserPicSelection = ({ user }) => {
  const classes = useStyles();
  const firstLetter = user ? getFirstLetter(user.name, user.email) : '';
  return (
    <Grid className={classes.root} 
       container alignItems="center">
        <Grid
          className={classes.grid}
          item>
          <Avatar
            className={classes.avatar}>
            { firstLetter || <PersonIcon /> }
          </Avatar>
        </Grid>
        <Grid 
          className={classes.grid}
          item>
          <Button
            className={classes.button}
            variant="contained">
            { user? 'Cambiar Imagen' : 'Agregar imagen' }
          </Button>
        </Grid>
    </Grid>
  );
};

export default UserPicSelection;