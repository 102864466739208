import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: { 
    fontFamily: 'roboto',
    marginBottom: theme.spacing(2.5),
    '&.fullWidth': {
      width: '100%'
    }
  },
  label: {
    display: 'block',
    fontWeight: '500',
    color: '#a9a9a9',
    marginBottom: theme.spacing(1)
  },
  detail: {
    fontSize: '16px',
    '&.big': {
      fontSize: '26px'
    }
  }
}));

const UserDetail = ({ label, detail, bigText, color, fullWidth }) => {
  const classes = useStyles();
  return (
    <Grid item
      className={`${ classes.root } ${ fullWidth? 'fullWidth' : '' }`}>
      <label
        className={classes.label}>
        { label }
      </label>
      <span
        className={`${ classes.detail } ${ bigText? 'big': '' }`}
        style={{ color }}>
        { detail }
      </span>
    </Grid>
  );
};

export default UserDetail;