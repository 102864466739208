import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import DragHandleIcon from '@material-ui/icons/Reorder';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import AttachmentIcon from '@material-ui/icons/Attachment';
const useStyles = makeStyles({
  list: {
    width: '250px',
  },
  fullList: {
    width: 'auto',
  },
  button: {
    height: '100%',
    cursor: 'pointer',
    color: '#999999',
    fontSize: '40px'
  }
});

const MobileDrawer = ({ links }) => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);

  const toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const SideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
      >
      <List>
        {
          links.map(link => {
            const Icon = link.icon;
            return (
              <Link onClick={() => setOpen(false)} to={link.to} key={link.to}>
                <ListItem button>
                  <ListItemIcon><Icon /></ListItemIcon>
                  <ListItemText primary={link.text}/>
                </ListItem>
              </Link>
            )
          })
        }
      </List>
      <Divider />
      <List>
        <Link onClick={() => setOpen(false)} to='/profile'>
          <ListItem button>
            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
            <ListItemText primary="Mi Perfil"/>
          </ListItem>
        </Link>
        <Link onClick={() => setOpen(false)} to='/noticePrivacy'>
          <ListItem button>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="Aviso de privacidad"/>
          </ListItem>
        </Link>
        <Link onClick={() => setOpen(false)} to='/logout'>
          <ListItem button>
            <ListItemIcon><MeetingRoomIcon /></ListItemIcon>
            <ListItemText primary="Cerrar sesión"/>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      <DragHandleIcon
        className={classes.button}
        onClick={toggleDrawer(true)}/>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}>
         <SideList /> 
      </SwipeableDrawer>
    </div>
  );
}

export default MobileDrawer;