import React, { useState } from 'react';
import Container from './../../../components/Container';
import Table from './../../../components/Table';
import ViewHeader from './../../../components/HeaderView';
import TableIconButton from './../../../components/TableIconButton';
import DeleteDialog from './../../../components/DeleteDialog';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import ActivityLevel from './../../../components/ActivityLevel';
import Loader from './../../../components/Loader';
import { useSelector } from 'react-redux';
import useFiltered from './../../../hooks/useFiltered';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import {
  useFirestoreConnect, isLoaded, useFirestore
} from 'react-redux-firebase';
import EventsDialog from './EventsDialog';


const useStyles = makeStyles(theme => ({
  actions: {
    '& > *': {
      margin: theme.spacing(0,0.7)
    }
  }
}));

const header = [
  {
    prop: 'name',
    name: 'Nombre'  
  },
  {
    prop: 'registry',
    name: 'Registro'
  }
];

const GroupsView = ({match, history }) => {
  const classes = useStyles();
  const firestore = useFirestore();
  useFirestoreConnect(['groups', 'events']);
  const groups = useSelector(state => state.firestore.ordered.groups);
  const events = useSelector(state => state.firestore.ordered.events);
  const [ idGroupToDelete, setIdGroupToDelete ] = useState(null);
  const [ groupEventActives, setGroupEventActives] = useState([]);
  const [ groupEventInactives, setGroupEventInactives] = useState([]);
  const [ openEventsDialog, setOpenEventsDialog] = useState(false);
  const [ page, setPage] = useState(parseInt(match.params.page) || 0);
  const [ groupsEventsCount, setGroupsEventsCount ] = useState(0);

  if (!isLoaded(groups) || !isLoaded(events)) {
    return <Loader />;
  }

  const handleEdit = id => {
    history.push(`/groups/edit/${ id }/${ page }`);
  }

  const handleAdd = () => {
    history.push(`/groups/create/${ page }`);
  }

  const handleDeleteSelect = id => {
    setIdGroupToDelete(id);
  }

  const handleDelete = () => {
    firestore
      .collection('groups')
      .doc(idGroupToDelete)
      .delete()
      .then(() => {
        setIdGroupToDelete(null);
      });
  }

  const showEvents = idGroup => {
    let groupEvents = [];
    let _groupEventActives = [];
    let _groupEventInactives = [];

    events.forEach(event => {
      if(event.groups.includes(idGroup)) {
        groupEvents.push(event);
      }
    });

    if(groupEvents.length) {
      const groupEventsOrdered = groupEvents.sort((a, b) => (a.name && a.name > b.name) ? 1 : -1);

      groupEventsOrdered.forEach(groupEvent => {
        if(groupEvent.dateFinal == '' || (groupEvent.dateFinal != '' &&  groupEvent.dateFinal >= moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'))) {
          _groupEventActives.push(groupEvent);
        }
        else {
          _groupEventInactives.push(groupEvent);
        }
      });
    }

    setGroupsEventsCount(groupEvents.length);
    setGroupEventActives(_groupEventActives);
    setGroupEventInactives(_groupEventInactives);
    setOpenEventsDialog(true);
  }

  return (
    <> 
      <ViewHeader
        rowsCount={groups.length}
        Text="Grupos"/>
      <Container>
        <Table
          filter={['name']}
          paginated
          header={header}
          data={groups}
          extraRows={[
            {
              prop: 'activity-level',
              name: 'Nivel de actividad',
              cell: row => <ActivityLevel level={row.level || 0}/>
            },
            {
              prop: 'users-count',
              name: 'Usuarios',
              cell: row => row.users && row.users.length || 0
            },
            {
              prop: 'active-events',
              name: 'Eventos',
              cell: row => (
                <TableIconButton
                  onClick={() => showEvents(row.id)}
                  icon={Visibility}/>
              )
            },
            {
              prop: 'actions',
              name: 'Modificar',
              cell: row => (
                <span className={classes.actions}>
                  <TableIconButton
                    onClick={() => handleEdit(row.id)}
                    icon={Edit}/>
                  <TableIconButton
                    onClick={() => handleDeleteSelect(row.id)}
                    icon={Delete}/>
                </span>
              )
            }
          ]}
          _page={page}
          onChangePage={page => setPage(page)}
          />
        <Grid container justify="flex-end">
          <Button
            onClick={handleAdd}
            variant="contained"
            color="secondary">
              Nuevo Grupo
          </Button>
        </Grid>
      </Container>
      <DeleteDialog
        open={Boolean(idGroupToDelete)}
        onClose={() => setIdGroupToDelete(null)}
        onCancel={() => setIdGroupToDelete(null)}
        onAccept={() => handleDelete()}
      />
      <EventsDialog
        open={openEventsDialog}
        onAccept={() => setOpenEventsDialog(false) }
        groupEventActives={groupEventActives}
        groupEventInactives={groupEventInactives}
        groupsEventsCount={groupsEventsCount}
      />
    </>
  );
};

export default GroupsView;