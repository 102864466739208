import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '20px',
    backgroundColor: '#ff6c5c'
  }
}));

const ActivityLevel = ({ level }) => {
  const classes = useStyles();
  return (
    <LinearProgress
      className={classes.root}
      variant="determinate"
      color="secondary"
      value={level}
      />
  );
};

export default ActivityLevel;