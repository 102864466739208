import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore'

// Producción
var config = {
  apiKey: "AIzaSyB__jwTvLRoIkuaxVaogpEa0QlWWa7MF4w",
  authDomain: "yagermx.firebaseapp.com",
  databaseURL: "https://yagermx.firebaseio.com",
  projectId: "yagermx",
  storageBucket: "yagermx.appspot.com",
  messagingSenderId: "580842935701",
  appId: "1:580842935701:web:aa1e2f8c30ec36224f129c",
  measurementId: "G-3SFMW2P3GT"
};

/*/// Demo
var config = {
  apiKey: "AIzaSyCbSZG9RSDTwa---BLirgJmRgho5N16myU",
  authDomain: "fourier-demo.firebaseapp.com",
  databaseURL: "https://fourier-demo.firebaseio.com",
  projectId: "fourier-demo",
  storageBucket: "fourier-demo.appspot.com",
  messagingSenderId: "845845242329"
};//*/

firebase.initializeApp(config);
firebase.firestore();

export default firebase;