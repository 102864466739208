import { useState, useEffect } from 'react';

const useFiltered = (items, fields, searchtext) => {

  const [ filteredItems, setFilteredItems ] = useState([]);

  useEffect(() => {
    if (searchtext) {
      const matches = item => {
        return fields
          .some(
            prop => item[prop] && item[prop]
              .toString()
              .toUpperCase()
              .includes(
                searchtext
                  .toString()
                  .toUpperCase()
              )
          );
      }
      const filteredItems = items.filter(matches);
      setFilteredItems(filteredItems);
    }
    else {
      setFilteredItems(items || []);
    }
  }, [ items, searchtext ]);

  return filteredItems;

}

export default useFiltered;