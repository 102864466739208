import React, { useState, useEffect } from 'react';
import { Container, Card, Typography, IconButton, Button, Box} from '@material-ui/core';
import HeaderView from '../../../components/HeaderView';
import GroupFormView from '../GroupFormView/GroupFormView';
import Alert from '../../../components/Alert';
import Table from '../../../components/Table';
import { isLoaded, useFirestore } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment-timezone';

const useStyles = makeStyles(() => ({
    fontTitles : {
        fontSize: 25,
        color: '#757575'
    },
    icon: {
        color: '#a1a1a1',
    },
    btn: {
        backgroundColor: '#ffc107', 
        color: 'white',
    },
}));

const header = [
    {
        prop: 'name',
        name: 'Nombre',
    },
    {
        prop: 'city',
        name: 'Ciudad'
    },
    {
        prop: 'registry',
        name: 'Registro'
    }
];

const GroupEditView = ({match, history}) =>  {
    const classes = useStyles();
    const firestore = useFirestore();
    const [group, setGroup] = useState({});
    const [error, setError ] = useState(false);
    const [success, setSuccess] = useState(false); 
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [modeAddUser, setModeAddUser] = useState(false);
    const [groupUsers, setGroupUsers] = useState([]);
    const [groupCodes, setGroupCodes] = useState([]);
    const [groupEvents, setGroupEvents] = useState([]);
    
    useEffect(() => {
        if (!loaded) {
            firestore
              .collection('users').orderBy('name').onSnapshot(async snapshot =>  { 
                if(snapshot.size) { 
                    const _users = snapToArray(snapshot.docs);   
                    const _groupDocument = await getGroup();
                    const _groupUsers = getGroupUsers(
                        _groupDocument.data().users ? _groupDocument.data().users : [],
                        _users
                    );
                    const usersOutsideGroup = getUsersOutsideGroup(
                        _groupUsers ? _groupUsers : [],
                        _users
                    );
                    const _groupEvents = await getGroupEvents();
                    
                    setGroupEvents(_groupEvents);
                    setGroupCodes(_groupDocument.data().codes);
                    setGroup(_groupDocument.data());
                    setUsers(usersOutsideGroup);
                    setGroupUsers(_groupUsers);
                    setLoaded(true);
                }
            });
        }
    }, [loaded])

    if (!isLoaded(users)) {
        return <Loader />;
    }

    const getGroup = async () => {
        return await firestore.collection('groups').doc(match.params.id).get();
    }

    const getGroupUsers = (usersIds, _users) => {
        let groupUsers = [];

        if(usersIds.length > 0) {
            usersIds.forEach(userId => {
                _users.forEach(user => {
                    if(user.id == userId) {
                        groupUsers.push(user);
                    }
                })
            });
        }

        return groupUsers;
    }

    const getUsersOutsideGroup = (groupUsers, _users) => {
        let usersOutsideGroup = _users;

        if(groupUsers.length > 0) {
            groupUsers.forEach(user => {
                const index = usersOutsideGroup.indexOf(user);
                usersOutsideGroup.splice(index, 1);
            });
        }

        return usersOutsideGroup;
    }
    
    const snapToArray = docs => {
        return docs
        .map(doc => ({ 
            id: doc.id,
            ...doc.data()
        }));
    }

    const handleChange = group => {
        setGroup(group);
    }
    
    const handleSubmit = event => {
        event.preventDefault();

        const groupObject = getGroupUpdateObject();

        firestore.collection('groups').doc(match.params.id).update(groupObject)
            .then(async () => {
                if(groupUsers.length) {
                    for (let i = 0; i < groupUsers.length; i++) {
                        for (let x = 0; x < groupEvents.length; x++) {
                            if(!groupEvents[x].users.includes(groupUsers[i].id) 
                                && (groupEvents[x].dateFinal == '' || groupEvents[x].dateFinal >= moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'))) {
                                groupEvents[x].users.push(groupUsers[i].id);
                                await firestore.collection('events').doc(groupEvents[x].id).update({users : groupEvents[x].users})
                            }
                        }
                    }
                }

                history.push("/groups/list/" + match.params.page);   
            })
            .catch(() => setError(true));
    }
    
    const getGroupUpdateObject = () => {
        const groupObject = {};
        const groupProps = ['name', 'city', 'description', 'codes'];

        groupProps.forEach(prop => {
            groupObject[prop] = group[prop] || '';
        });

        groupObject.codes = getCodes(groupObject.codes ? groupObject.codes : 0);
        groupObject.users = getGroupUserIds();
        
        return groupObject;
    }

    const getCodes = codes =>  {
        let arrayCodes = [... groupCodes];
        let code = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for(let index = 0; index < codes; index++) {
            for (let i = 0; i < 6; i++) {
                code += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            arrayCodes.push(code);
            code = '';
        }

        return arrayCodes;
    }

    const getGroupUserIds  = () => {
        let userIds = [];
        
        groupUsers.forEach(user => {
            userIds.push(user.id);
        })

        return userIds;
    }

    const handleClickAddUser = user => {
        const indexUser = users.indexOf(user);
        const groupUsersCopy = [... groupUsers];
        const usersCopy = [... users]

        usersCopy.splice(indexUser, 1);
        groupUsersCopy.push(user);
        
        setGroupUsers(groupUsersCopy);
        setUsers(usersCopy);
    }

    const handleClickDeleteUser = user => {
        const indexUser = groupUsers.indexOf(user);
        const groupUsersCopy = [... groupUsers];
        const usersCopy = [... users]

        groupUsersCopy.splice(indexUser, 1);
        usersCopy.push(user);
        
        setGroupUsers(groupUsersCopy);
        setUsers(usersCopy);
    }

    const handleChangeMode = () => {
        setModeAddUser(!modeAddUser);
    }

    const getGroupEvents = async () => {
        let groupEvents = [];

        const eventsQuery = await firestore.collection('events').where('groups', "array-contains", match.params.id).get();

        if(eventsQuery.docs) {
            groupEvents = snapToArray(eventsQuery.docs);   
        }
        
        return groupEvents;
    }

    return (
        <div style={{ marginBottom: 100}}>
            <HeaderView Text='Editar grupo' /> 
            <br />
            <Container>
                <Card>
                    <Container>
                        <GroupFormView 
                            group={group}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            submitLabel="GUARDAR"
                            edit
                            groupCodes={groupCodes}
                            groupEvents={groupEvents}
                            onBack={() => history.push("/groups/list/" + match.params.page)}
                        />
                    </Container>    
                </Card>
                <br />
                <Card>
                    <Container>
                        <br />
                        <div style={{ width: '100%' }}>
                            <Box display="flex" p={1} bgcolor="background.paper">
                                <Box p={1} flexGrow={1} >
                                    <Typography className={classes.fontTitles}>
                                    { modeAddUser ? 'USUARIOS FUERA DEL GRUPO' : 'USUARIOS DEL GRUPO' }
                                    </Typography>
                                </Box>
                                <Box p={1}>
                                    <Button variant="contained" className={classes.btn}
                                        type="button" onClick={handleChangeMode} fullWidth 
                                    >
                                    {  modeAddUser ? 'VER USUARIOS DEL GRUPO' : 'VER USUARIOS FUERA DEL GRUPO' }
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                        <Table
                            paginated
                            header={header}
                            data={modeAddUser ? users : groupUsers}
                            filter={['name', 'phone', 'email', 'facebook', 'twitter', 'instagram']}
                            extraRows={[
                                {
                                    prop: 'code',
                                    name: 'Codigo',
                                    cell: row => 
                                        row.groups && row.groups.find(group => group.id == match.params.id)
                                            ? row.groups.find(group => group.id == match.params.id).code
                                            : 'Sin codigo'
                                },
                                {
                                    prop: 'icon',
                                    name: 'Acción',
                                    cell: row => 
                                        !modeAddUser
                                        ?
                                            <IconButton color="primary"
                                                onClick={() => {handleClickDeleteUser(row)}} component="span"
                                            > 
                                                <DeleteIcon className={classes.icon}/> 
                                            </IconButton>
                                        :
                                            <IconButton color="primary"
                                                onClick={() => {handleClickAddUser(row)}}
                                                component="span"
                                            > 
                                                <AddIcon className={classes.icon}/> 
                                            </IconButton>
                                }
                            ]}
                        />
                    </Container>
                </Card>
            </Container>
            <Alert
                open={error}
                onClose={() => setError(false)}
                message={'No se pudo crear el grupo.'} 
                severity="error"
            />
            <Alert
                open={success}
                onClose={() => setSuccess(false)}
                message={'El grupo se creó exitosamente.'} 
            />  
        </div>
    );
}

export default GroupEditView;