import React, { useState } from 'react';
import { Grid, Button, Box, Typography, TextField, Card } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    btn: {
        backgroundColor: '#ffc107', 
        color: 'white',
    },
    fontTitles : {
        fontSize: 25,
        color: '#757575'
    },
}));

const GroupFormView = ({ group, onChange, onSubmit, submitLabel, edit, groupCodes, groupEvents, onBack }) =>  {
    const classes = useStyles();
    const [ invalidFields,  setInvalidFields ] = useState([]);
    const {
        name = '',
        city = '', 
        description = '',
        codes = ''
    } = group;
    
    const handleChange = event => {
        const { name, value } = event.target;
        const modifiedGroup = { ...group, [name]: value };
        onChange(modifiedGroup);
    }

    const handleBeforeSubmit = () => {
        let invalidFields = [];

        if (!group.name) {
            invalidFields.push('name');
        }
        if (!group.city) { 
            invalidFields.push('city'); 
        }
        if (!group.description) { 
            invalidFields.push('description');
        }

        setInvalidFields(invalidFields);
    }

    const handleSubmit = event => {
        event.preventDefault();
        
        if (invalidFields.length === 0) {
            onSubmit && onSubmit(event);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ width: '100%' }}>
                <Box display="flex" p={1} bgcolor="background.paper">
                    <Box p={1} flexGrow={1} >
                        <Typography className={classes.fontTitles}>DETALLES</Typography>
                    </Box>
                    <Box p={1}>
                        <Button variant="contained" 
                            type="button" onClick={onBack} 
                            className={classes.btn} 
                        >
                                REGRESAR
                        </Button>
                    </Box>
                    <Box p={1}>
                        <Button variant="contained" 
                            type="submit" onClick={handleBeforeSubmit}
                            className={classes.btn}>
                            { submitLabel || 'Guardar' }
                        </Button>
                    </Box>
                </Box>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField variant="filled" fullWidth required onChange={handleChange} 
                        value={name} name='name' label="Nombre del grupo" 
                        error={invalidFields.includes('name')}
                        helperText={'El "Nombre del grupo" es requerido.'}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField variant="filled" fullWidth required onChange={handleChange} 
                        value={city} name='city' label="Ciudad"  
                        error={invalidFields.includes('city')}
                        helperText={'La "Ciudad" es requerida.'}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField type='number' variant="filled" fullWidth
                        onChange={handleChange} 
                        value={!Array.isArray(codes) ? codes : ''} name='codes' label="Cantidad de codigos" 
                        error={invalidFields.includes('codes')}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField variant="filled" multiline required rows="4" fullWidth 
                        onChange={handleChange} style={{marginTop: 2}}
                        value={description} name='description' label="Descripción" 
                        error={invalidFields.includes('description')}
                        helperText={'La "Descripción" es requerida.'}
                    />
                </Grid>
                {   
                    edit
                    ?
                        <>
                        <Grid item xs={12} md={3}>
                            <Card style={{marginTop:3}}>
                                <div style={{height: 112, overflowY: 'auto', overflowX:'hidden'}}>
                                { groupCodes && groupCodes.map((code) => (
                                    <div style={{margin:10}} key={code}>
                                        <Typography>{code}</Typography>
                                    </div>
                                ))}
                                </div>
                            </Card> 
                            <Typography style={{color: '#757575', fontSize: 12, marginTop: 5}}>Codigos disponibles.</Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Card style={{marginTop:3}}>
                                <div style={{height: 112, overflowY: 'auto', overflowX:'hidden'}}>
                                { groupEvents && groupEvents.map((event) => (
                                    <div style={{margin:10}} key={event.id}>
                                        <Typography>{event.name}</Typography>
                                    </div>
                                ))}
                                </div>
                            </Card> 
                            <Typography style={{color: '#757575', fontSize: 12, marginTop: 5}}>Eventos del grupo.</Typography>
                        </Grid>
                        </>
                    :
                        null
                }
              
           </Grid>
        </form>
    );
}

export default GroupFormView;