import React from 'react';
import Paper from '@material-ui/core/Paper';
import {  
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search as SearchIcon } from '@material-ui/icons';

const Searchbox = ({ onChange, placeholder, className }) => {
  return (
    <Paper className={`${className} paper`} style={{ padding: 0, margin: '0 0 20px 0' }}>
      <TextField 
        fullWidth
        type="search" 
        variant="outlined"
        placeholder={placeholder}
        onChange={e => {
          onChange(e);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}/>
    </Paper>
  );
};

export default Searchbox;