import React, { useState, useEffect } from 'react';
import { Container, Card, Typography, IconButton } from '@material-ui/core';
import HeaderView from '../../../components/HeaderView';
import GroupFormView from '../GroupFormView/GroupFormView';
import Alert from '../../../components/Alert';
import moment from 'moment-timezone';
import Table from '../../../components/Table';
import { isLoaded, useFirestore } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(() => ({
    fontTitles : {
        fontSize: 25,
        color: '#757575'
    },
    icon: {
        color: '#a1a1a1',
    },
}));

const header = [
    {
        prop: 'name',
        name: 'Nombre',
    },
    {
        prop: 'city',
        name: 'Ciudad'
    },
    {
        prop: 'registry',
        name: 'Registro'
    }
];

const GroupCreateView = ({match, history}) =>  {
    const classes = useStyles();
    const firestore = useFirestore();
    const [group, setGroup] = useState({});
    const [error, setError ] = useState(false);
    const [success, setSuccess] = useState(false); 
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        if (!loaded) {
            firestore
              .collection('users').orderBy('name').onSnapshot(snapshot =>  { 
                if (snapshot.size) { 
                    const _users = snapToArray(snapshot.docs);   
                    setUsers(_users);
                    setLoaded(true);
                }
            });
        }
        
    }, [loaded])
    
    if (!isLoaded(users)) {
        return <Loader />;
    }
    
    const snapToArray = docs => {
        return docs
        .map(doc => ({ 
            id: doc.id,
            ...doc.data()
        }));
    }

    const handleChange = group => {
        setGroup(group);
    }
    
    const handleSubmit = event => {
        event.preventDefault();

        firestore.collection('groups').doc().set(getGroupObject())
            .then(() => {
                history.push("/groups/list/" + match.params.page);
            })
            .catch((error) =>  {
                console.log(error);
                setError(true)
            });
    }

    const getGroupObject = () => {
        const groupObject = {};
        const groupProps = ['name', 'city', 'description', 'codes'];

        groupProps.forEach(prop => {
            groupObject[prop] = group[prop] || '';
        });

        groupObject.registry = moment().tz('America/Hermosillo').format('DD/MM/YYYY');
        groupObject.codes = getCodes(groupObject.codes ? groupObject.codes : 0);
        groupObject.users = getUserIds();

        return groupObject;
    }

    const getCodes = (codes) =>  {
        let arrayCodes = [];
        let code = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for(let index = 0; index < codes; index++) {
            for (let i = 0; i < 6; i++) {
                code += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            arrayCodes.push(code);
            code = '';
        }

        return arrayCodes;
    }

    const getUserIds = () => {
        let usersIds = [];

        users.forEach(user => {
            if(user.added) {
                usersIds.push(user.id);
            }
        })

        return usersIds;
    }

    const handleClickAddUser = user => {
        const indexUser = users.indexOf(user);
        const usersCopy = [... users];
        usersCopy[indexUser].added = true;

        setUsers(Object.values(usersCopy));
    }

    const handleClickDeleteUser = user => {
        const indexUser = users.indexOf(user);
        const usersCopy = [... users];
        usersCopy[indexUser].added = false;
        
        setUsers(usersCopy);
    }
  
    return (
        <div style={{ marginBottom: 100}}>
            <HeaderView Text='Agregar grupo' /> 
            <br />
            <Container>
                <Card>
                    <Container>
                        <GroupFormView 
                            group={group}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            submitLabel="Agregar"
                            onBack={() => history.push("/groups/list/" + match.params.page)}
                        />
                    </Container>    
                </Card>
                <br />
                <Card>
                    <Container>
                        <br />
                        <Typography className={classes.fontTitles}>AGREGAR USUARIOS</Typography>
                        <Table
                            paginated
                            header={header}
                            data={users}
                            filter={['name']}
                            extraRows={[
                                {
                                    prop: 'icon',
                                    name: 'Acción',
                                    cell: row => row.added
                                    ?
                                        <IconButton color="primary" aria-label="upload picture" 
                                            onClick={() => {handleClickDeleteUser(row)}} component="span"
                                        > 
                                            <DeleteIcon className={classes.icon}/> 
                                        </IconButton>
                                    :
                                        <IconButton color="primary" aria-label="upload picture" 
                                            onClick={() => {handleClickAddUser(row)}}
                                            component="span"
                                        > 
                                            <AddIcon className={classes.icon}/> 
                                        </IconButton>
                                }
                            ]}
                        />
                    </Container>
                </Card>
            </Container>
            <Alert
                open={error}
                onClose={() => setError(false)}
                message={'No se pudo crear el grupo.'} 
                severity="error"
            />
            <Alert
                open={success}
                onClose={() => setSuccess(false)}
                message={'El grupo se creó exitosamente.'} 
            />  
        </div>
    );
}

export default GroupCreateView;