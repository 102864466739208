import React, { useState, useEffect } from 'react';
import HeaderView from '../../components/HeaderView';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import { Container, Grid, TextField, Avatar, Button, Typography, Select, MenuItem, InputLabel, 
    FormControl, LinearProgress, Box } from '@material-ui/core';
import Loader from '../../components/Loader';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestore } from 'react-redux-firebase';

const BorderLinearProgress = withStyles({
    bar: {
      borderRadius: 20,
      backgroundColor: '#ff6c5c',
    },
})(LinearProgress);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        height : '87vh'
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    starIcon: {
        color: '#ffc107', 
        paddingRight: 10,
    },
    fontTitles : {
        color: '#757575'
    },
    fontSubTitles : {
        fontSize: 25
    },
    marginBar: {
        marginTop: 10
    },
    button : {
        backgroundColor: '#ffc107', color: 'white',marginTop: 20, marginBottom: 60
    }
}));

const ProfileView = ({ match, history }) => {
    const classes = useStyles();
    const firestore = useFirestore();
    const auth = useSelector(state => state.firebase.auth);
    const [dataUser, setDataUser] = useState(null);
    const [errors, setErrors] = useState({
        errorName: false, errorEmail: false, errorCode: false, errorPhone: false, errorCity: false 
    });

    const [errorEmail, setErrorEmail] = useState('El "Correo electronico" es requerido.');

    useEffect(() => {
        const unsubscribe = firestore.collection('users').doc(auth.uid).onSnapshot((doc) => {
            setDataUser(doc.data())
        });

        return () =>  {
            unsubscribe()
        }
    }, [auth.uid])
    
    //#region Command

    const handleChangeSex = event => {
        setDataUser({ ...dataUser, sex: event.target.value });
      /*   const sex = event.target.value; setDataUser({ ...dataUser, sex }); */
    }

    const onChangeName = event => {
        setDataUser({ ...dataUser, name: event.target.value });
    }

    const onChangeEmail = event => {
        setDataUser({ ...dataUser, email: event.target.value });
    }

    const onChangeCode = event => {
        setDataUser({ ...dataUser, code: event.target.value });
    }
    
    const onChangePhone = event => {
        setDataUser({ ...dataUser, phone: event.target.value });
    }

    const onChangeCity = event => {
        setDataUser({ ...dataUser, city: event.target.value });
    }

    const update = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if(!dataUser.name || !dataUser.email || !dataUser.code || !dataUser.phone || !dataUser.city || !re.test(dataUser.email)) {
            const errors2 = {...errors};
            
            !dataUser.name ? errors2.errorName = true : errors2.errorName = false;

            if(!dataUser.email) {
                errors2.errorEmail = true;
                setErrorEmail('El "Correo electronico" es requerido.');
            }
            else {
                if(!re.test(dataUser.email)) {
                    errors2.errorEmail = true;
                    setErrorEmail('Escriba un "Correo electrinico" valido.')
                }
                else {
                    errors2.errorEmail = false;
                }
            }
           
            !dataUser.code ? errors2.errorCode = true : errors2.errorCode = false;

            !dataUser.phone ? errors2.errorPhone = true : errors2.errorPhone = false;

            !dataUser.city ? errors2.errorCity = true : errors2.errorCity = false;

            setErrors(errors2);
        }
        else {
            firestore.collection('users').doc(auth.uid).update(dataUser).then(() => {
                history.push('/');
            });
        }
    }
    //#endregion
    
    if(!isLoaded(auth) || !dataUser) { 
        return <Loader />;
    }

    return (
        <div className={classes.root} >
            <HeaderView
                Text='Perfil'/>
            <Container>
                <Grid container spacing={2} style={{marginTop:30}}>
                    <Grid item xs={6} md={1}>
                        <Avatar className={classes.large}>H</Avatar>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Button variant="contained" style={{backgroundColor: '#9e9e9e', marginTop: 12}}> Editar imagen</Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField required onChange={onChangeName} defaultValue={dataUser.name} 
                            variant="filled" fullWidth label="Nombre completo" 
                            error={errors.errorName} helperText={'El "Nombre completo" es requerido.'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel >Genero</InputLabel>
                            <Select
                                value={dataUser.sex}
                                onChange={handleChangeSex}
                            >
                            <MenuItem value="h">Hombre</MenuItem>
                            <MenuItem value="m">Mujer</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop:20}}>
                    <Grid item xs={12} md={4}>
                        <Typography className={classes.fontTitles}>Nivel de actividad</Typography>
                        
                        <BorderLinearProgress
                            className={classes.marginBar}
                            variant="determinate"
                            color="secondary"
                            value={dataUser.level ? dataUser.level : 0}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField required onChange={onChangeEmail} defaultValue={dataUser.email} 
                            variant="filled" fullWidth label="Correo electronico" 
                            error={errors.errorEmail} helperText={errorEmail}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField required onChange={onChangeCode} defaultValue={dataUser.code} 
                            variant="filled" fullWidth label="Codigo"
                            error={errors.errorCode} helperText={'El "Codigo" es requerido.'}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop:20}}>
                    <Grid item xs={4} md={1}>
                        <Typography className={classes.fontTitles} >Atendidos</Typography>
                        <Typography className={classes.fontSubTitles} style={{color: '#8bc34a'}}>5</Typography>
                    </Grid>
                    <Grid item xs={4} md={1}>
                        <Typography className={classes.fontTitles}>Pendientes</Typography>
                        <Typography className={classes.fontSubTitles}>7</Typography>
                    </Grid>
                    <Grid item xs={4} md={1}>
                        <Typography className={classes.fontTitles}>Icompletos</Typography>
                        <Typography className={classes.fontSubTitles} style={{color: 'red'}}>10</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField required onChange={onChangePhone} defaultValue={dataUser.phone} 
                            variant="filled" fullWidth label="Celular"
                            error={errors.errorPhone} helperText={'El "Celular" es requerido.'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField required variant="filled" InputProps={{
                            readOnly: true}} fullWidth label="Rol" 
                            defaultValue={dataUser.role} 
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop:20}}>
                    <Grid item xs={6} md={2}>
                        <Typography className={classes.fontTitles} >Proveedor</Typography>
                        <Typography >Facebook</Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography className={classes.fontTitles} >Fecha de registro</Typography>
                        <Typography>15/01/2020</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField required onChange={onChangeCity} defaultValue={dataUser.city} 
                            variant="filled" fullWidth label="Ciudad" 
                            error={errors.errorCity} helperText={'La "Ciudad" es requerida.'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField type="password" required defaultValue="" 
                            variant="filled" fullWidth label="Contraseña" 
                        />
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" onClick={update} className={classes.button} >ACTUALIZAR</Button>
                </Box>
            </Container>
        </div>
    );
}

export default ProfileView;