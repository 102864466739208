import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Typography } from '@material-ui/core';
import ActivityLevel from '../EventsView/ActivityLevel';

const useStyles = makeStyles(theme => ({
    fontTitles: {
        color: '#757575'
    },
    fontSubTitles : {
        fontSize: 25
    },
    marginBar: {
        marginTop: 10
    },
    detail: {
        fontSize: '16px',
        '&.big': {
          fontSize: '26px'
        }
    }
}));

const EventProgresDetailView = ({event, theyFulfilled, pending, level}) => {
    const classes = useStyles();
    const {
        name = '',
        city = '',
        description = '',
        url = '',
        net = '',
        dateInitial = '',
        dateFinal = '',
    } = event;
    const usersTheyFulfilled = theyFulfilled ? theyFulfilled : 0;
    const usersPending = pending ? pending : 0;
    const eventLevel = level ? level : 0;
    
    return (
        <>
            <Grid item xs={12} md={4}>
                <Typography className={classes.fontTitles}>Nivel de actividad</Typography>
                <ActivityLevel level={eventLevel} />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField required variant="filled" InputProps={{readOnly: true}}
                    defaultValue={name} fullWidth label="Nombre del evento" 
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField required variant="filled" InputProps={{readOnly: true}}
                    defaultValue={city} fullWidth label="Ciudad" 
                />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={6} md={2}>
                <Typography className={classes.fontTitles} >Cumplieron</Typography>
            <Typography className={classes.fontSubTitles} style={{color: '#8bc34a'}}>{usersTheyFulfilled}</Typography>
            </Grid>
            <Grid item xs={6} md={2}>
                <Typography className={classes.fontTitles}>Pendientes</Typography>
                <Typography className={classes.fontSubTitles}>{usersPending}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField required variant="filled" InputProps={{readOnly: true}}
                    defaultValue={description} multiline rows="2" 
                    fullWidth label="Descripción"
                />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}>
                <TextField required variant="filled" InputProps={{readOnly: true}}
                    defaultValue={url} fullWidth label="URL" 
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField required variant="filled" InputProps={{readOnly: true}}
                    defaultValue={net} fullWidth label="Red social" 
                />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={6}>
                <TextField variant="filled" InputProps={{readOnly: true}} fullWidth
                    defaultValue={dateInitial} label="Fecha de inicio" 
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField variant="filled" InputProps={{readOnly: true}} fullWidth
                    defaultValue={dateFinal} label="Fecha de finalización" 
                />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
        </>
    );
}

export default EventProgresDetailView;