import React  from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import image from './../../web_image.png';
import appStore from './../../app_store.png';
import googlePlay from './../../google_play.png';

const WebView = () => {
    return (
        <div>
            <Container style={{marginTop: 30, paddingBottom: '100px'}} >
                <Typography>
                    Yager es una aplicación inteligente y divertida. Permite reunir a grupos de interés entre sí,  para conocer temas que son afines a la comunidad de Yager. Los eventos permiten que el usuario adjunte fotografías que compartir con los miembros de un equipo. Comparta la información de eventos y fechas mediante el Código QR para otros usuarios. Reciba noticias y eventos de diversas redes sociales, para conocer sitios, asistir a conciertos, fiestas locales y eventos VIP. Con solo instalar recibe día a día eventos e invitaciones, y posteriormente participar en redes sociales para unirse a grupos VIP de Yager mediante invitaciones y recomendaciones.
                </Typography>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={2} md={1}></Grid>
                    <Grid item xs={10} md={4}>
                        <a href="https://apps.apple.com/mx/app/yager/id1507294488">
                            <img src={appStore} style={{width: '300px', marginTop: 20}}></img>
                        </a>
                    </Grid>
                    <Grid item xs={2} md={3}></Grid>
                    <Grid item xs={10} md={3}>
                    <a href="https://play.google.com/store/apps/details?id=mx.yager.app">
                        <img src={googlePlay} style={{width: '300px'}}></img>
                    </a>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                </Grid>

                <br/>
                <Typography>
                    Blvd. Morelos 307, planta baja
                </Typography>
                <Typography>
                    edificio H, Col. Militar XV.
                </Typography>
                <Typography>
                    C.P. 83145 Hermosillo, Sonora.
                </Typography>
                <br />
                <Grid container>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={10}>
                        <img src={image} style={{width: '100%'}} />
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default WebView;