import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Container2 from './../../../components/Container';
import Alert from './../../../components/Alert';
import Loader from './../../../components/Loader';
import UserForm from './../UserForm';
import UserDetails from './../UserDetails';
import ViewHeader from './../../../components/HeaderView';
import Table from './../../../components/Table';
import moment from 'moment-timezone';
import { useFirestoreConnect, isLoaded, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Typography, Card, Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const header = [
  {
    prop: 'model',
    name: 'Modelo'
  },
];

const useStyles = makeStyles(() => ({
  fontTitles : {
      fontSize: 22,
      color: '#757575'
  },
}));

const UserEditView = ({ match, history }) => {
  const classes = useStyles();
  const firestore = useFirestore();
  const [ user, setUser ] = useState(null);
  const [ error, setError ] = useState(false);
  const [ succes, setSucces ] = useState(false); 
  const [ userEventsData, setUserEventsData] = useState(null);
  const [ userLevel, setUserLevel] = useState(0.00);
  const [ loaded, setLoaded] = useState(false);
  useFirestoreConnect(['events']);
  const events = useSelector(state => state.firestore.ordered.events);

  useEffect(() => {
    if(events) {
      firestore
      .collection('users')
      .doc(match.params.id)
      .onSnapshot(async userDoc => {
        setUser(userDoc.data());
        getUserEventsData([...events]);
        setLoaded(true);
      });
    }
  }, [events]);

  const getUserEventsData = events => {
    if(events.length > 0) {
      const evenExampleIndex = events.map(event => event.id).indexOf('I9A5wFOTSoQgWzFeLP0i'); 
      events.splice(evenExampleIndex, 1);
      const evenExampleTwoIndex = events.map(event => event.id).indexOf('QcbR8k4e9s74BSUiLqkw'); 
      events.splice(evenExampleTwoIndex, 1);

      const eventsIncompleteCount = events.filter(event => (event.users && event.users.includes(match.params.id)) && (event.dateFinal && event.dateFinal < moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) 
        && (!event.usersWhitEventComplete || event.usersWhitEventComplete && !event.usersWhitEventComplete.includes(match.params.id)) ).length;
      
      const eventsCompleteCount = events.filter(event => event.usersWhitEventComplete && event.usersWhitEventComplete.includes(match.params.id)).length;
      
      const eventsPendingCount = events.filter(event => (event.users && event.users.includes(match.params.id)) 
        && (!event.usersWhitEventComplete || (event.usersWhitEventComplete && !event.usersWhitEventComplete.includes(match.params.id)))
        && (event.dateFinal == "" || event.dateFinal > moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) ).length;
      
      let userPercentage = ((eventsCompleteCount 
        / (eventsCompleteCount + eventsPendingCount + eventsIncompleteCount)) * 100).toFixed(2);
     
      if(isNaN(userPercentage)) { userPercentage = (0).toFixed(2) }
      
      setUserLevel(userPercentage);
      setUserEventsData({
        eventsCompleteCount: eventsCompleteCount, 
        eventsPendingCount: eventsPendingCount, 
        eventsIncompleteCount: eventsIncompleteCount
      })
    }
  }

  if (!isLoaded(events) || !loaded) {
    return <Loader />;
  }

  const handleChange = user => {
    setUser(user);
  }

  const handleSubmit = event => {
    event.preventDefault();
    firestore
      .collection('users')
      .doc(match.params.id)
      .update(getUserUpdateObject())
      .then(() => setSucces(true))
      .catch(() => setError(true));
  }

  const getUserUpdateObject = () => {
    const userProps = ['city', 'code', 'email', 'level',
      'name', 'phone', 'registry', 'role', 'sex'];
    const userUpdateObject = {};
    userProps.forEach(prop => {
      userUpdateObject[prop] = user[prop] || '';
    });
    return userUpdateObject;
  }

  return (
    <>
      <ViewHeader 
        Text="Editar Usuario"/>
      <Container2 press>
        <Grid container spacing={2}>
          <UserDetails
            user={user}
            userEventsData={userEventsData}
            userLevel={userLevel}
          />
          <UserForm
            user={user}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onBack={() => history.push("/users/list/" + match.params.page)}
          />
        </Grid>
        <Card>
          <div style={{ width: '100%' }}>
            <Box display="flex" p={1}>
              <Box p={2} flexGrow={1} >
                <Typography className={classes.fontTitles}>
                  {user.devices ? 'INFORMACIÓN DE DIPOSITIVOS MÓVILES' : 'SIN INFORMACIÓN DE DIPOSITIVOS MÓVILES'}
                </Typography>
              </Box>
            </Box>
          </div>
          <Container>
            <Table
              filter={['model']}
              paginated
              header={header}
              data={user.devices || []}
              extraRows={[
                {
                  prop: 'brand',
                  name: 'Marca',
                  cell: row => 
                    <>{row.brand ? row.brand : 'iPhone'}</>
                },
                {
                  prop: 'vercion',
                  name: 'SDK o Verción',
                  cell: row => 
                    <>{ row['version.sdkInt'] ? row['version.sdkInt'] : row.systemVersion}</>
                },
              ]}
            />
          </Container>
        </Card>
      </Container2>
      <Alert
        open={error}
        onClose={() => setError(false)}
        message={'No se pudo guardar la información del usuario.'} />
      <Alert
        open={succes}
        onClose={() => setSucces(false)}
        message={'La información del usuario se guardó exitosamente.'} />  
    </>
  );
};

export default UserEditView;