import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, Divider,
    Box } from '@material-ui/core';
import { 
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
    PhotoLibrary as PhotoLibraryIcon
} from '@material-ui/icons/';
import { Pie } from 'react-chartjs-2';

const PiesData = ({piesData, usersWhitEventComplete, usersEvidence, usersSawEvent, usersSawEventWeb, eventId, openModalEvidences}) => {
    return (
        <>
        {
            piesData && piesData.map((pieData, index) => (
                <Grid item xs={12} md={6} key={index}>
                    <Card >
                        <Box display="flex" justifyContent="center">
                            <Typography >{pieData.groupName}</Typography>
                        </Box>
                        <div>
                            <Pie data={pieData} options={{maintainAspectRatio: true}} />
                        </div>
                        <br />
                        <Grid container spacing={1}>
                            <Grid md={4} xs={4} item >
                                <Typography style={{fontSize: 11}}>&nbsp;&nbsp;USUARIO</Typography>
                            </Grid>
                            <Grid md={2}  xs={2}item>
                                <Typography style={{fontSize: 11}}>CUMPLIO</Typography>
                            </Grid>
                            <Grid md={2} xs={2} item>
                                <Typography style={{fontSize: 11}}>EVIDENCIA</Typography>
                            </Grid>
                            <Grid md={2} xd={2}item>
                                <Typography style={{fontSize: 11}}>RECIBIO EVENTO (1 CLICK)</Typography>
                            </Grid>
                            <Grid md={2}  xs={2}item>
                                <Typography style={{fontSize: 11}}>ENTRÓ EVENTO (2 CLICK)</Typography>
                            </Grid>
                        </Grid>
                        <div style={{height: 200, overflowY: 'auto', overflowX:'hidden'}}>
                        {
                            pieData.groupUsers && pieData.groupUsers.map((user) => (
                                <div  style={{margin:10}} key={user.id}>
                                    <Grid container spacing={2}>
                                        <Grid item md={4} xs={4}>
                                            <Typography>{user.name}</Typography>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                        {
                                            usersWhitEventComplete && usersWhitEventComplete.includes(user.id)
                                            ?
                                                <CheckCircleIcon style={{color: '#87AA3B'}} />
                                            :
                                                <CancelIcon style={{color: '#f44336'}} />
                                        } 
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                        {
                                            usersEvidence && usersEvidence.includes(user.id)
                                            ?
                                                <>
                                                <CheckCircleIcon style={{color: '#87AA3B'}} />
                                                {   
                                                    user.eventsEvidence && user.eventsEvidence.find(event => event.eventId == eventId) 
                                                    ? 
                                                        <PhotoLibraryIcon style={{color: '#87AA3B', cursor: 'pointer' }}
                                                            onClick={ () => openModalEvidences(user.eventsEvidence.find(event => event.eventId == eventId).evidences)}
                                                        />
                                                    : 
                                                        null
                                                }
                                                </>
                                            :
                                                <CancelIcon style={{color: '#f44336'}} />
                                        } 
                                        </Grid> 
                                        <Grid item md={2} xs={2}>
                                        {
                                            usersSawEvent && usersSawEvent.includes(user.id)
                                            ?
                                                <CheckCircleIcon style={{color: '#87AA3B'}} />
                                            :
                                                <CancelIcon style={{color: '#f44336'}} />
                                        } 
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                        {
                                            usersSawEventWeb && usersSawEventWeb.includes(user.id)
                                            ?
                                                <CheckCircleIcon style={{color: '#87AA3B'}} />
                                            :
                                                <CancelIcon style={{color: '#f44336'}} />
                                        } 
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </div>
                            ))
                        }
                        </div>
                    </Card>
                </Grid>
            ))
        }
        </>
    );
}

export default PiesData;