import React, { useState, useEffect } from 'react';
import HeaderView from '../../components/HeaderView';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Container, Grid, TextField, Button, Typography, Divider, 
    InputAdornment, FormControl, Select, MenuItem, InputLabel, Card,
    Table, TableCell, TableBody, TableRow, TableHead, IconButton, 
    TablePagination, Checkbox, Box, Input} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment-timezone';
import Loader from '../../components/Loader';
import { useFirestore } from 'react-redux-firebase';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import LanguageIcon from '@material-ui/icons/Language';
import firebase from './../../firebase';
import sinImagen from '../../SinImagen.png';
import Alert from './../../components/Alert';
import ActivityLevel from './EventsView/ActivityLevel';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        height : '88vh'
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    starIcon: {
        color: '#ffc107', 
        paddingRight: 0,
    },
    fontTitles: {
        color: '#757575'
    },
    fontSubTitles : {
        fontSize: 25
    },
    btnUpdate: {
        backgroundColor: '#ffc107', 
        color: 'white'
    },
    marginBar: {
        marginTop: 10
    },
    card: {
        marginBottom: 120
    },
    gridList: {
        height: 200,
    },
    imageEvent: {
        height: 170,
        width: 170,
        borderRadius: '5%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
}));

const actionsNames = [
    'Ver',
    'Me gusta',
    'No me gusta',
    'Compartir',
    'Comentario positivo',
    'Comentario negativo',
    'Reportar'
];

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

const FormEventsView = ({ match, history }) => {
    const classes = useStyles();
    const firestore = useFirestore();
    const [dataEvent, setDataEvent] = useState(
        match.params.id != 0 ? null 
        : { id : 0, net: 'Facebook',
            dateInitial: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'),
            dateFinal: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')
    });
    const [dataNewEvents, setDataNewEvents] = useState([{url: '', net: 'Facebook'}]);
    const [modeAdd, setModeAdd] = useState(false);
    const [dataGroups, setDataGroups] = useState({data: [], done: false});
    const [page, setPage ] = useState(0);
    const [rowsPerPage, setRowsPerPage ] = useState(5);
    const [length, setLength] = useState(0);
    const [query, setQuery] = useState("");
    const [doneEvent, setDoneEvent ] = useState(false);
    const [errors, setErrors] = useState({
        errorName: false, errorCity: false, errorDescription: false, errorUrl: false, errorDateFinal: false
    });
    const [withoutDateFinal, setWitoutDateFinal] = useState(false);
    const [imageEvent, setImageEvent] = useState();
    const [imageUrlEvent, setImageUrlEvent] = useState('');
    const [ error, setError ] = useState(null);
    const [loading, setLoading] = useState(false);
    const [eventLevel, setEventLevel] = useState(0);

    if(match.params.id != 0) {
        useEffect(() => {
            firestore.collection('events').doc(match.params.id).onSnapshot((doc) => {
                if(doc) {
                    const usersWhitEventCompleteCount = doc.data().usersWhitEventComplete ? doc.data().usersWhitEventComplete.length : 0
                    let _eventLevel = ((usersWhitEventCompleteCount / doc.data().users.length) * 100).toFixed(2) 
                    
                    if(isNaN(_eventLevel)) { _eventLevel = 0} 
                    
                    setDataEvent(doc.data());
                    setEventLevel(_eventLevel);
                    
                    if(doc.data().imageUrl) {
                        setImageUrlEvent(doc.data().imageUrl);
                    }
                    
                    firestore.collection('groups').orderBy('name').onSnapshot(snapshot => { 
                        if(snapshot.size) { 
                            const array = snapshot.docs.map(d => ({ id: d.id, ...d.data() }))
                            let newGroups = [];

                            if(doc.data().groups) {
                                for (let x = 0; x < doc.data().groups.length; x++) {
                                    for(let i = 0; i < array.length; i++) {
                                        if(array[i].id == doc.data().groups[x]) {
                                            newGroups.push(array[i]);
                                        }
                                    }
                                }
                            }
                            
                            setDataGroups({
                                data: newGroups,
                                done:true
                            })
                            setLength(newGroups.length)
                        }
                    }) 

                    setDoneEvent(true);
                }
            })
        }, [doneEvent])
    }
    else {
        useEffect (() => { 
            const unsubscribe = firestore.collection('groups').orderBy('name').onSnapshot(snapshot => { 
                if (snapshot.size) { 
                    setDataGroups({
                        data: snapshot.docs.map(d => ({ id: d.id, ...d.data() })),
                        done: true
                    });
                    setDoneEvent(true);
                    setLength(snapshot.size)
                }
            }) 
            return () => { 
              unsubscribe () 
            } 
        }, []);
    }

    //#region Commands
    const handleChangeAction = event => {
        setDataEvent({ ...dataEvent, actions: event.target.value });
    };

    const handleChangeNet = event => {
        setDataEvent({ ...dataEvent, net: event.target.value });
    };

    const onChangeName = event => {
        setDataEvent({ ...dataEvent, name: event.target.value });
    }

    const onChangeCity = event => {
        setDataEvent({ ...dataEvent, city: event.target.value });
    }

    const onChangeDescription = event => {
        setDataEvent({ ...dataEvent, description: event.target.value });
    }

    const onChangeUrl = event => {
        setDataEvent({ ...dataEvent, url: event.target.value });
    }

    const onChangeDateInitial = event => {
        setDataEvent({ ...dataEvent, dateInitial: event.target.value });
    }

    const onChangeDateFinal = event => {
        setDataEvent({ ...dataEvent, dateFinal: event.target.value });
    }

    const addOrUpdate = async () => {
        setLoading(true);
        const errors2 = {...errors};
      
        if(match.params.id == 0) {
            let contErrors = 0;

            for (let index = 0; index < dataNewEvents.length; index++) {
                if(!dataNewEvents[index].url) {
                    contErrors ++;
                }
            }

            contErrors > 0 ? errors2.errorUrl = true : errors2.errorUrl = false;
        }
        else {
            !dataEvent.url ? errors2.errorUrl = true : errors2.errorUrl = false;

            if(!withoutDateFinal)
                !dataEvent.dateFinal ? errors2.errorDateFinal = true : errors2.errorDateFinal = false;
            else
                errors2.errorDateFinal = false;
        }

        if(!dataEvent.name || !dataEvent.city || !dataEvent.description || errors2.errorUrl || errors2.errorDateFinal) {
            
            !dataEvent.name ? errors2.errorName = true : errors2.errorName = false;
            
            !dataEvent.city ? errors2.errorCity = true : errors2.errorCity = false;

            !dataEvent.description ? errors2.errorDescription = true : errors2.errorDescription = false;

            setErrors(errors2);
        }
        else {
            if(withoutDateFinal) {
                dataEvent.dateFinal = '';
            }
            if(match.params.id == 0) {
                dataEvent.action = "";
                dataEvent.evidencePaths = [];
                dataEvent.level = 0;
                dataEvent.registry = moment().tz("America/Hermosillo").format('DD/MM/YYYY'); 
                dataEvent.usersEvidence = [];
            
                
                let arrayGroups = [];
                let arrayUsers = [];

                for(let index = 0; index < dataGroups.data.length; index++) {
                    if(dataGroups.data[index].added) {
                        arrayGroups.push(dataGroups.data[index].id);
                        for(let i = 0; i < dataGroups.data[index].users.length; i++) {
                            if(!arrayUsers.includes(dataGroups.data[index].users[i])) {
                                arrayUsers.push(dataGroups.data[index].users[i]);
                            }
                        }
                    }              
                }

                dataEvent.users = arrayUsers;
                dataEvent.groups = arrayGroups;
                dataEvent.image = imageEvent ? imageEvent.name : '';
                delete dataEvent.id;

                if(dataEvent.image != '') {
                    const image = await  firebase.storage().ref()
                        .child("ImagenesEventos/" + imageEvent.name).put(imageEvent);

                    const imageUrl = await image.ref.getDownloadURL();

                    dataEvent.imageUrl = imageUrl.toString();
                }

                for(let index = 0; index < dataNewEvents.length; index++) {
                    dataEvent.url = dataNewEvents[index].url;
                    dataEvent.net = dataNewEvents[index].net;
                    firestore.collection('events').doc().set(dataEvent);
                }

                history.push('/events/list/'+ match.params.page);
            }
            else {
                firestore.collection('groups').onSnapshot(async snapshot => { 
                    if(snapshot.size) { 
                        let newGroups = [];
                        let arrayUsers = [];
                        const array = snapshot.docs.map(d => ({ 
                            id: d.id, ...d.data(), 
                        }));

                        for(let x = 0; x < dataEvent.groups.length; x++) {
                            for(let i = 0; i < array.length; i++) {
                                if(array[i].id == dataEvent.groups[x]) {
                                    newGroups.push(array[i]);
                                }
                            }
                        }

                        for(let index = 0; index < newGroups.length; index++) {
                            for(let i = 0; i < newGroups[index].users.length; i++) {
                                if(!arrayUsers.includes(newGroups[index].users[i])) {
                                    arrayUsers.push(newGroups[index].users[i]);
                                }
                            }
                        }

                        dataEvent.users = arrayUsers;

                        if(imageEvent) {
                            firebase.storage().ref().child("ImagenesEventos/" + dataEvent.image).delete();
                            
                            dataEvent.image = imageEvent.name;

                            const image = await firebase.storage().ref()
                                .child("ImagenesEventos/" + imageEvent.name).put(imageEvent);
                            const imageUrl = await image.ref.getDownloadURL();

                            dataEvent.imageUrl = imageUrl.toString();
                        }

                        firestore.collection('events').doc(match.params.id).update(dataEvent).then(() => {
                            history.push('/events/list/'+ match.params.page);
                        });
                    }
                });
            }
        }
        setLoading(false);
    }

    const onChangeQuery = event => {
        setQuery(event.target.value);
        setLenght(dataGroups.data.filter(user => user.name
            .toLowerCase()
            .includes(query)).length)
    }

    const handleClickAddGroup= id => {
        const dataGroups2 = {...dataGroups};
        const index = dataGroups2.data.map(group => group.id).indexOf(id);  
        dataGroups2.data[index].added = true;
        setDataGroups(dataGroups2);
    }

    const handleClickDeleteGroup = id => {
        const dataGroups2 = {...dataGroups};
        const index = dataGroups2.data.map(group => group.id).indexOf(id);
        dataGroups2.data[index].added = false;
        setDataGroups(dataGroups2);
    }

    const handleClickGetGroupsNoAddedOrAdded = () => {
        setPage(0);

        if(dataEvent.dateFinal == '' || dataEvent.dateFinal > moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')) {
            firestore.collection('groups').onSnapshot(snapshot => { 
                if(snapshot.size) { 
                    const array = snapshot.docs.map(d => ({ id: d.id, ...d.data()}))
                    
                    if(!modeAdd) {
                        let newGroups = [];
    
                        if(!dataEvent.groups || dataEvent.groups.length == 0) {
                            for(let i = 0; i < array.length; i++) {
                                newGroups.push(array[i]);
                            }
    
                            setDataGroups({
                                data: newGroups,
                                done: true
                            })
                            setLength(newGroups.length);
    
                        }
                        else {
                            for (let x = 0; x < dataEvent.groups.length; x++) {
                                for(let i = 0; i < array.length; i++) {
                                    if(array[i].id == dataEvent.groups[x]) {
                                        array.splice(i, 1);
                                    }
                                }
                            }
    
                            setDataGroups({
                                data: array,
                                done: true
                            })
                            setLength(array.length);
    
                        }
                        setModeAdd(true);
                    } 
                    else {
                        let newGroups = [];
    
                        for (let x = 0; x < dataEvent.groups.length; x++) {
                            for(let i = 0; i < array.length; i++) {
                                if(array[i].id == dataEvent.groups[x]) {
                                    newGroups.push(array[i]);
                                }
                            }
                        }
    
                        setDataGroups({
                            data: newGroups,
                            done: true
                        })
                        setModeAdd(false);
                        setLength(newGroups.length);
                    }
                }
            }) 
        }
        else {
            setError('No se puede editar los grupos de un evento finalizado.');
        }
    }

    const handleClickDeleteGroupAdded = id => {
        const dataEvent2 = {...dataEvent}
        const dataGroups2 = {...dataGroups}
        const indexTable = dataGroups2.data.map(group => group.id).indexOf(id);
        const index = dataEvent2.groups.map(group => group).indexOf(id);  
        dataEvent2.groups.splice(index, 1);
        dataGroups2.data.splice(indexTable, 1);
        setDataGroups(dataGroups2);
        setLength(dataGroups2.data.length);
    }

    const handleClickAddUserNoAdded = id => {
        const dataEvent2 = {...dataEvent}
        if(!dataEvent2.groups) {
            dataEvent2.groups = [];
        }
        dataEvent2.groups.push(id);
        setDataEvent(dataEvent2);

        const dataGroups2 = {...dataGroups};
        const indexTable = dataGroups2.data.map(group => group.id).indexOf(id); 
        dataGroups2.data.splice(indexTable, 1);
        setDataGroups(dataGroups2);
        setLength(dataGroups2.data.length);
    }

    const handleClickAddEvent = () => {
        const dataNewEvents2 = {...dataNewEvents};
        let arrayEvents = Object.values(dataNewEvents2);
        arrayEvents.push({url: '', net: 'Facebook'});
        setDataNewEvents(arrayEvents);
    }

    const handleClickDeleteEvent = index => {
        const dataNewEvents2 = {...dataNewEvents};
        let arrayEvents = Object.values(dataNewEvents2);
        if(arrayEvents.length > 1) {
            arrayEvents.splice(index, 1);
            setDataNewEvents(arrayEvents);
        }
    }

    const onChangeUrlAdd = (event,index) => {
        const dataNewEvents2 = {...dataNewEvents};
        let arrayEvents = Object.values(dataNewEvents2);
        arrayEvents[index].url = event.target.value;
        setDataNewEvents(arrayEvents);
    }

    const handleChangeNetAdd = (event, index) => {
        const dataNewEvents2 = {...dataNewEvents};
        let arrayEvents = Object.values(dataNewEvents2);
        arrayEvents[index].net = event.target.value;
        setDataNewEvents(arrayEvents);
    }

    const handleChangeWithoutDateFinal = () => {
        setWitoutDateFinal(!withoutDateFinal);
    }

    const loadImage = (image) => {
        setImageEvent(image);
        setImageUrlEvent(URL.createObjectURL(image));
    }
    //#endregion
    
    if(!dataGroups.done || !doneEvent) { 
        return <Loader />;
    }

    return (
        <div className={classes.root} >
            <HeaderView
                Text={'DETALLES'}
            />
            <Container>
                <Grid container spacing={1} style={{marginTop:30}}>
                    <div style={{ width: '100%' }}>
                        <Box display="flex">
                            <Box flexGrow={1} style={{marginBottom:30}}>
                                <Button variant="contained" className={classes.btnUpdate} onClick={() => history.push('/events/list/'+ match.params.page)}>
                                    Regresar
                                </Button>
                            </Box>
                            <Box style={{marginBottom:30}}>
                            {
                                !loading
                                ?
                                    <Button variant="contained" className={classes.btnUpdate} onClick={addOrUpdate}>
                                        {match.params.id == 0 ? 'AGREGAR' : 'GUARDAR'}
                                    </Button>
                                :
                                    null
                            }
                            </Box>
                        </Box>
                    </div>
                    {
                        match.params.id != 0 
                        ?   <>
                            <Grid item xs={6} md={2}>
                                <Typography className={classes.fontTitles}>Nivel de actividad</Typography>
                                <ActivityLevel level={eventLevel} />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography className={classes.fontTitles}>Fecha de registro</Typography>
                                <Typography style={{marginTop:7}}>{dataEvent ? dataEvent.registry : ''}</Typography>
                            </Grid>
                            </>
                        :
                            <>
                            <Grid item xs={12} md={2}>
                                <Button variant="contained" component="label">
                                    Imagen de evento
                                    <Input type="file" accept="image/x-png,image/gif,image/jpeg"
                                     style={{ display: "none" }} onChange={ e => loadImage(e.target.files[0])}
                                    />
                                </Button>    
                            </Grid>
                             <Grid item xs={12} md={2}>
                                { imageUrlEvent != '' ? <img src={imageUrlEvent} className={classes.imageEvent} /> : null }
                            </Grid>
                            </>    
                    }
                    <Grid item xs={12} md={4}>
                        <TextField required variant="filled" onChange={onChangeName} 
                            defaultValue={dataEvent ? dataEvent.name : ''} fullWidth label="Nombre del evento" 
                            error={errors.errorName} helperText={'El "Nombre del evento" es requerido.'}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField required variant="filled" onChange={onChangeCity} 
                            defaultValue={dataEvent ? dataEvent.city : ''} fullWidth label="Ciudad" 
                            error={errors.errorCity} helperText={'La "Ciudad" es requerida.'}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{marginTop:20}}>
                {
                    match.params.id != 0 
                    ?
                        <>
                        <Grid item xs={6} md={2}>
                            <Typography className={classes.fontTitles} >Cumplieron</Typography>
                            <Typography className={classes.fontSubTitles} style={{color: '#8bc34a'}}>{dataEvent.usersWhitEventComplete ? dataEvent.usersWhitEventComplete.length : 0}</Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography className={classes.fontTitles}>Pendientes</Typography>
                            <Typography className={classes.fontSubTitles}>{dataEvent.users.length - (dataEvent.usersWhitEventComplete ? dataEvent.usersWhitEventComplete.length : 0)}</Typography>
                        </Grid>
                        </>
                    :
                        <Grid item xs={12} md={4}></Grid>

                }
                <Grid item xs={12} md={8}>
                    <TextField required variant="filled" onChange={onChangeDescription} 
                        defaultValue={dataEvent ? dataEvent.description : ''} multiline rows="2" 
                        fullWidth label="Descripción"
                        error={errors.errorDescription} helperText={'La "Descripción" es requerida.'}
                    />
                </Grid>
                </Grid>
                {
                    match.params.id == 0
                    ?
                        <div style={{ paddingTop: 30, height: dataNewEvents.length <= 1 ? 90 : 180, overflowY: 'auto', overflowX:'hidden'}}>
                        {dataNewEvents.map((event, index) => (
                            <Grid container spacing={1} key={index}>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={1}>
                                {
                                    index == 0 
                                    ? 
                                        <IconButton style={{marginTop:10}} onClick={handleClickAddEvent} >
                                            <AddIcon color="primary"/>
                                        </IconButton>
                                    :
                                        null
                                }
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <TextField required variant="filled" value={event.url} onChange={(e) => onChangeUrlAdd(e, index)} 
                                        fullWidth label="URL" 
                                        error={errors.errorUrl && !event.url} helperText={'La "Url" es requerida.'}
                                    />
                                </Grid>
                                <Grid item xs={5} md={3}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel >Red social</InputLabel>
                                        <Select
                                            value={event.net}
                                            onChange={(e) => handleChangeNetAdd(e, index)}
                                        >
                                            <MenuItem value="Facebook">Facebook</MenuItem>
                                            <MenuItem value="Twitter">Twitter</MenuItem>
                                            <MenuItem value="Instagram">Instagram</MenuItem>
                                            <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                                            <MenuItem value="YouTube">YouTube</MenuItem>
                                            <MenuItem value="TikTok">TikTok</MenuItem>
                                            <MenuItem value="Internet">Otro<LanguageIcon /></MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1} md={1}>
                                    <IconButton style={{marginTop:10}}  color="primary" onClick={() => {handleClickDeleteEvent(index)}} component="span"> 
                                        <DeleteIcon /> 
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        </div>
                    :
                        <Grid container spacing={1} style={{marginTop:30}}>
                            <Grid item xs={6} md={2}>
                            { 
                                <img src={imageUrlEvent ? imageUrlEvent : sinImagen} className={classes.imageEvent} /> 
                            }
                            </Grid>
                            <Grid item xs={6} md={2}>
                            { 
                                <Button variant="contained" component="label">
                                    Imagen de evento
                                    <Input type="file" accept="image/x-png,image/gif,image/jpeg"
                                        style={{display: "none"}} onChange={e => loadImage(e.target.files[0])}
                                    />
                                </Button>    
                            }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required variant="filled" onChange={onChangeUrl} 
                                    defaultValue={dataEvent ? dataEvent.url : ''} fullWidth label="URL" 
                                    error={errors.errorUrl} helperText={'La "Url" es requerida.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl variant="filled" fullWidth>
                                <InputLabel >Red social</InputLabel>
                                <Select
                                    value={dataEvent.net}
                                    onChange={handleChangeNet}
                                >
                                    <MenuItem value="Facebook">Facebook</MenuItem>
                                    <MenuItem value="Twitter">Twitter</MenuItem>
                                    <MenuItem value="Instagram">Instagram</MenuItem>
                                    <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                                    <MenuItem value="YouTube">YouTube</MenuItem>
                                    <MenuItem value="TikTok">TikTok</MenuItem>
                                    {/* <MenuItem value="Reportes"></MenuItem> */}
                                    <MenuItem value="Internet">Otro<LanguageIcon /></MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                        </Grid>
                }
                <Grid container spacing={1} style={{marginTop:30}}>
                    <Grid item xs={6} md={3}>
                        <TextField label="Fecha de inicio" onChange={onChangeDateInitial} value={dataEvent.dateInitial} 
                            variant="filled"  fullWidth type="datetime-local" 
                            InputLabelProps={{ 
                                shrink: true
                            }} 
                            InputProps={{ 
                                inputProps: { 
                                    min: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm') 
                                } 
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField disabled={withoutDateFinal} label="Fecha de finalización" onChange={onChangeDateFinal} 
                            value={dataEvent.dateFinal} variant="filled" fullWidth type="datetime-local" 
                            InputLabelProps={{ 
                                shrink: true
                            }} 
                            InputProps={{ 
                                inputProps: { 
                                    min: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm') 
                                }
                            }}
                            error={errors.errorDateFinal && !withoutDateFinal} 
                            helperText={match.params.id != 0 && !withoutDateFinal ? 'La "Fecha de finalización es requerida.' : ''}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} >
                        <Typography align='center'>Sin fecha de finalización</Typography>
                        <Box display="flex" justifyContent="center">
                            <Checkbox value={withoutDateFinal} onChange={handleChangeWithoutDateFinal} ></Checkbox>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth variant="filled" className={classes.formControl}>
                            <InputLabel id="demo-mutiple-name-label">Acción</InputLabel>
                            <Select
                                multiple
                                value={dataEvent.actions ? dataEvent.actions : []}
                                onChange={handleChangeAction}
                                input={<Input />}
                                MenuProps={MenuProps}
                            >
                            {actionsNames.map(name => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <br/><br/><br/>
                <Divider />
                <Grid container spacing={2} style={{marginTop:10}}>
                    <Grid item xs={12} md={7} >
                        <Typography className={classes.fontSubTitles} style={{color: '#757575'}}>{match.params.id == 0 ? 'AGREGAR GRUPOS' : modeAdd ? 'AGREGAR GRUPOS' : 'GRUPOS ASIGNADOS'} </Typography>
                    </Grid>
                    <Grid item xs={7} md={3}>
                            <TextField  color="secondary" placeholder="Buscar grupos" fullWidth 
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={onChangeQuery}
                            />
                    </Grid>
                    <Grid item xs={5} md={2}>
                    { match.params.id != 0 ? <Button variant="contained" onClick={handleClickGetGroupsNoAddedOrAdded} fullWidth className={classes.btnUpdate}>{ modeAdd ? 'GRUPOS ASIGNADOS' : 'AGREGAR GRUPOS'}</Button> : null } 
                    </Grid>
                </Grid>
                <br/>
                <Card className={classes.card}>
                    <div style={{overflowX: 'auto'}}>   
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell >Usuarios</TableCell>
                                    <TableCell >Eventos activos</TableCell>
                                    <TableCell >Registro</TableCell>
                                    <TableCell align='center'>Acción</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {dataGroups.data.filter(group => group.name
                                    .toLowerCase()
                                    .includes(query))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row"> {row.name} </TableCell>
                                    <TableCell component="th" scope="row"> {row.users ? row.users.length : 0} </TableCell>
                                    <TableCell component="th" scope="row"> {0} </TableCell>
                                    <TableCell component="th" scope="row"> {row.registry} </TableCell>
                                    <TableCell align='center' component="th" scope="row"> 
                                            { 
                                                match.params.id == 0 
                                                ?
                                                    row.added
                                                    ?
                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => {handleClickDeleteGroup(row.id)}} component="span"> 
                                                            <DeleteIcon /> 
                                                        </IconButton>
                                                    
                                                    :
                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => {handleClickAddGroup(row.id)}} component="span"> 
                                                            <AddIcon /> 
                                                        </IconButton>
                                                    
                                                :   
                                                    
                                                    !modeAdd
                                                    ?
                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => {handleClickDeleteGroupAdded(row.id)}} component="span"> 
                                                            <DeleteIcon /> 
                                                        </IconButton>
                                                    :
                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => {handleClickAddUserNoAdded(row.id)}} component="span"> 
                                                            <AddIcon /> 
                                                        </IconButton>
                                            }
                                            </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        count={length}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={(e, page) => setPage(page)}
                        onChangeRowsPerPage={e => {setRowsPerPage(e.target.value); setPage(0);}}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count}`}
                        labelRowsPerPage={'Renglones por página'}
                        /> 
                </Card>
            </Container>
            <Alert
                open={Boolean(error)}
                onClose={() => setError(null)}
                message={error}
                severity="error"
            />
        </div>
    );
}

export default FormEventsView;