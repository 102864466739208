import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Email as EmailIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'roboto',
    fontSize: '12px',
    display: 'flex',
    color: '#ffffff',
    alignItems: 'center',
    padding: theme.spacing(3,0,1,0)
  },
  icon: {
    color: "#ffc107",
    fontSize: "17px",
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span>Derechos resevados <b>YAGER</b> ® &nbsp;&nbsp;| &nbsp;</span>
      <EmailIcon className={classes.icon}/> &nbsp;&nbsp;
      <span>Contacto</span>
    </div>
  );
};

export default Footer;