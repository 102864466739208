import React from 'react';
import { Chip } from '@material-ui/core';

const ActivityLevel = ({ level }) => {
  return (
    <>
      <Chip label={level + '%'} style={{
        color: 'white', 
        backgroundColor: 
          level == 100
            ? '#87AA3B'
            : level >= 61  && level <= 99
            ? '#ffc400'
            : '#ff1744'
      }} />
    </>
  );
};

export default ActivityLevel;