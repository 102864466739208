import React, {useEffect} from 'react';
import firebase from './../firebase';

const LogOutView = (props) => {
  
  useEffect (() => { 
    firebase.logout().then(() => {
      props.history.push("/login");
    }).catch((error) => console.log(error))

  }, [])

  return null;
};

export default LogOutView;